import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Header from '../../components/header';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Table } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import { GET_ALL_CRONS } from '../../gql/cron';

const Index = () => {
  const today = new Date();
  const [monthCounter, setMonthCounter] = useState(0);
  const { data, loading } = useQuery(GET_ALL_CRONS);
  const monthContainer = (monthCounter: number) => {
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1 + monthCounter, 0);
    const lastDate = lastDay.getDate();
    console.log(lastDay, lastDate, data);
    const rows = [];
    let counter = 1;
    do {
      const cells = [];
      for (let i = 0; i < 7; i++) {
        lastDay.setDate(counter);
        const day = lastDay.getDay();
        if (i !== day) {
          cells.push(tableCell('-', cellColor(7)));
        } else if (counter <= lastDate) {
          console.log(lastDay.getTime());
          const d = data.getAllCron.find((d: any) => {
            const dateTime = new Date(d.date);
            const onlyDate = new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate());
            return onlyDate.getTime() === lastDay.getTime();
          });
          if (d) {
            const dateTime = new Date(d.date);
            cells.push(
              tableCell(counter, cellColor(day), d.description, dateTime.getHours(), (dateTime.getMinutes()).toString())
            );
          } else {
            cells.push(
              tableCell(counter, cellColor(day))
            );
          }
          ++counter;
        } else {
          cells.push(tableCell('-', cellColor(7)));
        }
      }
      rows.push(<TableRow>{cells}</TableRow>);
    } while (counter <= lastDate);
    return rows;
  };
  const cellColor = (day: number) => {
    switch (day) {
      case 0:
        return 'rgba(45,68,144,0.3)';
      case 1:
        return 'rgba(56,140,186,0.3)';
      case 2:
        return 'rgba(56,140,186,0.3)';
      case 3:
        return 'rgba(56,140,186,0.3)';
      case 4:
        return 'rgba(56,140,186,0.3)';
      case 5:
        return 'rgba(56,140,186,0.3)';
      case 6:
        return 'rgba(45,68,144,0.3)';
      default:
        return 'rgba(208,208,208,0.3)';
    }
  };
  const tableCell = (day: string | number, color: string, content?: string | number, hours?: number, minutes?: string) => (
    <TableCell>
      <Paper sx={{
        py: 5, backgroundColor: color, borderColor: color, borderWidth: 5, borderStyle: 'solid', maxHeight: 80
      }}
      >
        <Grid
          container
          alignItems="start"
          justifyContent="center"
        >
          <Grid item xs={12} sx={{ marginTop: -5, paddingLeft: 1 }}>
            <Typography textAlign="left">
              {day}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ backgroundColor: 'white', borderRadius: 1, marginX: 1 }}>
            <Typography textAlign="center" color="black" fontSize={14}>
              {content ? `${hours}:${minutes && Number(minutes) < 10 ? `0${minutes}` : minutes} - ${content}` : ''}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </TableCell>

  );
  useEffect(() => {
    console.log(monthCounter);
  }, [monthCounter]);

  return (
    <>
      <Header title="Calendario" />
      <Paper sx={{ width: '100%', mb: 2, p: 2 }}>
        <Grid container>
          <Grid item xs={3}>
            <ChevronLeft
              fontSize="large"
              cursor="pointer"
              onClick={() => {
                setMonthCounter(monthCounter - 1);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography textAlign="center" fontSize={36}>
              {new Date(today.getFullYear(), today.getMonth() + monthCounter).toLocaleString('pt-br', { month: 'long' })}
              {' '}
              -
              {' '}
              {new Date(today.getFullYear(), today.getMonth() + monthCounter).toLocaleString('pt-br', { year: 'numeric' })}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'right' }}>
            <ChevronRight
              fontSize="large"
              cursor="pointer"
              onClick={() => {
                setMonthCounter(monthCounter + 1);
              }}
            />
          </Grid>
        </Grid>
        {!loading && (
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: 'center', fontSize: 18 }}>
                  Domingo
                </TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 18 }}>
                  Segunda
                </TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 18 }}>
                  Terça
                </TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 18 }}>
                  Quarta
                </TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 18 }}>
                  Quinta
                </TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 18 }}>
                  Sexta
                </TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 18 }}>
                  Sabado
                </TableCell>
              </TableRow>
            </TableHead>
            {monthContainer(monthCounter)}
            <TableBody />
          </Table>
        )}

      </Paper>
    </>
  );
};

export default Index;
