import React from 'react';
import { FabProps } from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { FloatingButton } from './styles';

const CreateEntity = (props: FabProps) => (
  <FloatingButton color="secondary" aria-label="create" {...props}>
    <AddIcon />
  </FloatingButton>
);

export default CreateEntity;
