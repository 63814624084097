import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import ReduxThunk from 'redux-thunk';
import createReducer from './modules/reducer';

export default () => {
  const rootReducer = createReducer();
  const middlewares = applyMiddleware(ReduxThunk);
  const enhancers = composeWithDevTools({})(middlewares);
  const store = createStore(rootReducer, {}, enhancers);
  return { store };
};

