import React from 'react';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import LongToolTip from './style';

interface SpeedDialProps {
  actions: {
    key: string;
    icon: any;
    tooltipTitle: string;
    onClick: any;
  }[];
}
const SpeedDialComponent: React.FC<SpeedDialProps> = ({ actions }) => (
  <SpeedDial

    sx={{
      position: 'fixed',
      bottom: 15,
      right: 42,
      '& .MuiFab-primary': { width: 70, height: 70 },
      
    }}
    icon={<SpeedDialIcon />}
    ariaLabel="SpeedDial"
  >
    {actions.map(({ key, icon, tooltipTitle, onClick }) => (
      <SpeedDialAction
        key={key}
        icon={icon}
        tooltipTitle={<LongToolTip>{tooltipTitle}</LongToolTip>}
        tooltipOpen
        onClick={onClick}
      />
    ))}
  </SpeedDial>
);
export default SpeedDialComponent;
