import * as yup from 'yup';
import { requiredField, minField } from '../../../utils/forms';

const passwordResetSchema = yup.object({
  password: yup
    .string()
    .label('Senha')
    .min(8, minField(8, 'Senha'))
    .required(requiredField('Senha')),
  confirmPassword: yup
    .string()
    .label('Confirmar senha')
    .min(8, minField(8, 'Confirmar senha'))
    .oneOf([yup.ref('password')], 'Senhas devem corresponder')
    .required(requiredField('Confirmar senha'))
});

export default passwordResetSchema;
