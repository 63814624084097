import * as yup from 'yup';
import { requiredField } from '../../../utils/forms';

export default yup.object({
  id: yup
    .string()
    .label('id'),
  data: yup
    .array().of(
      yup.object().shape({
        manufacturerId: yup.string(),
        key: yup.string(),
        value: yup.string(),
        namespace: yup.string(),
        description: yup.string()
      })
    )
    .required(requiredField('JSON requerido')),
  partnerId: yup
    .string()
    .required()
});

