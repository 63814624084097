import styled from 'styled-components';
import Fab from '@mui/material/Fab';

export const FloatingButton = styled(Fab)`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 3em;
  padding: 2.5em;
  svg {
    font-size: 2.5em;
  }
`;

export default FloatingButton;
