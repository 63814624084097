import React from 'react';
import Header from '../../../components/header';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function EditAssessment() {
  return (
    <form>
      <Header title="Editar avaliação" />
      <Paper
        sx={{
          m: 'auto',
          p: 2,
          Color: '#000000',
        }}
      >
        <Grid item xs={12} container spacing={3}>
          <Grid xs={6} item>
            <TextField
              fullWidth
              margin="normal"
              id="cnpj"
              label="CNPJ"
              name="cnpj"
              variant="standard"
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              fullWidth
              margin="normal"
              id="razaoSocial"
              label="Razão Social"
              name="razaoSocial"
              variant="standard"
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              fullWidth
              margin="normal"
              id="nomeFantasia"
              label="Nome Fantasia"
              name="nomeFantasia"
              variant="standard"
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              fullWidth
              margin="normal"
              id="seller"
              label="Vendedor"
              name="seller"
              variant="standard"
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              fullWidth
              margin="normal"
              id="timeTrial"
              label="Tempo de Trial"
              name="timeTrial"
              variant="standard"
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              fullWidth
              margin="normal"
              id="statusTrial"
              label="Status de Trial"
              name="statusTrial "
              variant="standard"
            />
          </Grid>
        </Grid>
        <Grid item xs={6} mt={4} mb={2} display="flex" justifyContent="flex-end">
          <Button size="large" type="submit">
            Cancelar
          </Button>
          <Button variant="contained" size="large" type="submit">
            Salvar
          </Button>
        </Grid>
      </Paper>
    </form>
  );
}

export default EditAssessment;
