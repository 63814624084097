import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query getAllUsers {
    getAllUsers {
      id
      firstName
      lastName
      email
      role
      cpf
      partner{
        id
        tradingName
      }
    }
  }
`;


export const GET_MY_ID = gql`
  query getUserProfile {
    me {
      id
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query {
    me {
      id
      email
      firstName
      lastName
      partner{
        id
        tradingName
      }
      role
    }
  }
`;

export const GET_USER_ROLE = gql`
  query {
    me {
      id
      role
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($id: String!) {
    getUserById(id: $id) {
      id
      firstName
      lastName
      email
      role
      cpf
      partner{
        id
        tradingName
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser ($input: CreateUserInput!) {
    createUser (input: $input) {
      id
      email
      firstName
      lastName
      partnerId
      role
      cpf
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser ($input: UpdateUserInput!) {
    updateUser (input: $input) {
      id
    }
  }
`;

export const REMOVE_USER = gql`
  mutation removeUser ($id: String!) {
    removeUser (id: $id)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;
