import { AlertColor } from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { selectAlerts, selectGlobalLoading } from '../redux/selectors/app.selector';
import { dismissAlert, sendAlert as sendReduxAlert, setLoading } from '../redux/modules/app';

interface AlertI {
  message: string | undefined;
  level: AlertColor | undefined;
}

const useSnackbar = () => {
  const dispatch = useDispatch();
  const { displayAlert, currentAlert } = useSelector(selectAlerts);
  const isLoadingGlobal = useSelector(selectGlobalLoading);

  const sendAlert = (alert: AlertI) => dispatch(sendReduxAlert(alert));

  const hideAlert = () => dispatch(dismissAlert());

  const setGlobalLoading = (newLoadingState: boolean) => dispatch(setLoading(newLoadingState));

  return {
    sendAlert,
    hideAlert,
    displayAlert,
    currentAlert,
    isLoadingGlobal,
    setGlobalLoading
  };
};

export default useSnackbar;
