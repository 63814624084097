import React from 'react';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import CustomToolBar from '../tableToolBar';

const columnsDescription = [
  {
    field: 'Item',
    headerName: 'Item',
    flex: 1,
  },
  {
    field: 'Descrição',
    headerName: 'Descrição',
    flex: 1,
  },
  {
    field: '',
    headerName: 'Acessos',
    flex: 0,
    renderCell: () => (
      <Grid container justifyContent="space-between" sx={{ padding: '1px' }}>
        <Grid>
          <KeyIcon
            fontSize="small"
            style={{ transform: 'rotate(270deg)', color: 'grey' }}
          />
        </Grid>
        <Grid>
          <DeleteIcon fontSize="small" style={{ color: 'grey' }} />
        </Grid>
        <Grid>
          <EditIcon fontSize="small" style={{ color: 'grey' }} />
        </Grid>
      </Grid>
    ),
  },
];

const rowsDescription = [
  {
    id: 1,
    Item: 'Inicio',
    Descrição: 'Define as permissões na página de início',
  },
  {
    id: 2,
    Item: 'Parceiros',
    Descrição: 'Define as permissões na página de Parceiros',
  },
  {
    id: 3,
    Item: 'Calendário',
    Descrição: 'Define as permissões na página de Calendário',
  },
  {
    id: 4,
    Item: 'Pedidos De Venda',
    Descrição: 'Define as permissões na página de Pedidos de Venda',
  },
  {
    id: 5,
    Item: 'Contratos',
    Descrição: 'Define as permissões na página de Contratos',
  },
  {
    id: 6,
    Item: 'Produtos',
    Descrição: 'Define as permissões na página de Produtos',
  },
];

const tableUsers = () => (
  <Grid container item md={12} mt={5}>
    <DataGrid
      autoHeight
      density="standard"
      rows={rowsDescription}
      columns={columnsDescription}
      style={{
        width: '100%',
        background: 'white',
        padding: '2em',
        borderWidth: 1,
        borderStyle: 'solid',
      }}
      localeText={{
        toolbarDensity: 'Size',
        toolbarDensityLabel: 'Size',
        toolbarDensityCompact: 'Small',
        toolbarDensityStandard: 'Medium',
        toolbarDensityComfortable: 'Large',
      }}
      components={{
        Toolbar: CustomToolBar,
      }}
    />
  </Grid>
);

export default tableUsers;
