import Tab from '@mui/material/Tab';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import { ListItemText, ListItemIcon, Menu, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import { TabProps } from '@mui/material/Tab/Tab';
import { useNavigate } from 'react-router-dom';
import { MenuItemI } from '../../../../types/menu';

const subMenuProps = {
  icon: <KeyboardArrowDownIcon />,
  iconPosition: 'end',
};

const HeaderMenuItem = ({ item }: { item: MenuItemI }) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (item?.location) navigate(item.location, { replace: true });
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (location: string) => () => {
    handlePopoverClose();
    navigate(location, { replace: true });
  };

  const open = Boolean(anchorEl);
  const hasSubMenu = Boolean(item?.menus?.length);
  return (
    <>
      <Tab
        sx={{
          color: '#000',
        }}
        disabled={item.disabled}
        onClick={handlePopoverOpen}
        label={item.name}
        {...(hasSubMenu ? (subMenuProps as TabProps) : {})}
      />

      {hasSubMenu && (
        <Menu
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          {item?.menus?.map((subMenu) => (
            <MenuItem
              key={subMenu.location}
              disabled={subMenu.disabled}
              onClick={handleMenuClick(subMenu.location as string)}
            >
              <ListItemIcon>{subMenu.icon}</ListItemIcon>
              <ListItemText>
                <Typography sx={{ color: '#000' }}>{subMenu.name}</Typography>
              </ListItemText>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default HeaderMenuItem;
