import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment/moment';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import validationSchema from './schema';
import Grid from '@mui/material/Grid';
import {
  CREATE_CONTRACT,
  GET_ALL_CONTRACTS,
  GET_CONTRACT_BY_ID,
  UPDATE_CONTRACT,
} from '../../../gql/contracts';
import Button from '@mui/material/Button';
import useSnackbar from '../../../hooks/useSnackbar';
import { getTenantId } from '../../../services/tenancy';
import { GET_ALL_PRODUCTS_NAMES } from '../../../gql/products';
import { GET_ALL_USERS } from '../../../gql/users';
import { GET_ALL_PARTNERS } from '../../../gql/partners';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import { GET_ALL_TAXES } from '../../../gql/taxes';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import SelectDueDate from '../../../components/selectDueDate';
import YearMonthPicker from '../../../components/yearMonthPicker';
import ActionButton from '../../../components/actionButton';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import { getTierLabel } from '../../../utils/tiers';
import usePermissions from '../../../hooks/usePermissions';

interface ContractFormProps {
  uid?: string;
}

const ContractForm: React.FC<ContractFormProps> = ({ uid }) => {
  const [showModal, setShowModal] = useState(false);

  const { isAdmin, isOwner } = usePermissions();

  let { id } = useParams();
  id = uid ?? id;
  const isCreate = id === 'create';
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [mutateContract] = useMutation(isCreate ? CREATE_CONTRACT : UPDATE_CONTRACT, {
    refetchQueries: [{ query: GET_ALL_CONTRACTS }],
  });
  const { data: productData } = useQuery(GET_ALL_PRODUCTS_NAMES);
  const { data: usersData } = useQuery(GET_ALL_USERS);
  const { data: partnerData } = useQuery(GET_ALL_PARTNERS);
  const { data: taxesData, loading: loadingTaxes } = useQuery(GET_ALL_TAXES);
  const [getCurrentContract, { data }] = useLazyQuery(GET_CONTRACT_BY_ID, {
    fetchPolicy: 'no-cache',
  });
  const navigateBack = () => navigate('/dashboard/contracts');
  const [showRampUp, setShowRampUp] = useState<any>(false);
  const [showFreeTier, setShowFreeTier] = useState<any>(false);

  const handleSubmit: any = () => {
    snackbar.setGlobalLoading(true);
    const { values } = formik;
    snackbar.sendAlert({ level: 'info', message: 'Salvando Dados...' });

    const input: Record<string, any> = { tenantId: getTenantId() };
    Object.keys(validationSchema.fields).map((key) => {
      if (Object.hasOwn(values, key)) input[key] = values[key];
      return null;
    });
    mutateContract({ variables: { input } })
      .then(() =>
        snackbar.sendAlert({ level: 'success', message: 'Dados salvos com sucesso ' })
      )
      .catch((er) => {
        snackbar.sendAlert({ level: 'error', message: 'Erro Salvando dados' });
        console.error(er);
      })
      .finally(() => {
        snackbar.setGlobalLoading(false);
        navigateBack();
      });
  };
  const formik = useFormik({
    initialValues: data?.getContractById
      ? {
          ...data.getContractById,
          contractId: data.getContractById?.id,
          taxes: data.getContractById?.taxes ?? [],
          dueDates: data.getContractById.dueDates ?? [15],
          credit: data.getContractById.credit ?? [],
        }
      : {
          taxes: [],
          dueDates: [15],
          credit: [],
        },
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  const handleSwitch = (index: number) => {
    const { credit } = formik.values;
    credit[index].active = !credit[index].active;
    formik.setFieldValue('credit', credit);
  };
  const hideAutoComplete = () => setShowModal(false);

  const handleAutoComplete = () => setShowModal(true);

  const removeDiscount = (resourceIndex: number) => () => {
    const { credit } = formik.values;
    const newData = [...credit];
    newData.splice(resourceIndex, 1);
    formik.setFieldValue('credit', [...newData]);
  };
  const removeTax = (resourceIndex: number) => () => {
    const { taxes } = formik.values;
    const newData = [...taxes];
    newData.splice(resourceIndex, 1);
    formik.setFieldValue('taxes', [...newData]);
  };
  const handleChangeAutoComplete = (_: unknown, value: Record<string, string>) => {
    const { taxes } = formik.values;
    const newTax = { ...value, name: value.name ?? value.description ?? '--' };
    formik.setFieldValue('taxes', [...taxes, newTax]);
    hideAutoComplete();
  };
  const sortPartner = (partners: any) =>
    partners
      .slice()
      .sort(compare)
      .map((item: Record<any, any>) => (
        <MenuItem value={item.id} key={item.id}>
          {item.tradingName}
        </MenuItem>
      ));
  const compare = (a: any, b: any) => {
    if (a.tradingName < b.tradingName) {
      return -1;
    }
    if (a.tradingName > b.tradingName) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    if (!isCreate) {
      getCurrentContract({ variables: { id } }).then(({ data }) => {
        const { getContractById } = data;
        setShowRampUp(Boolean(getContractById.mmcFreeUntil));
        setShowFreeTier(Boolean(getContractById.freeUntil));
      });
    }
  }, [isCreate]);

  const toggleRampUp = () => {
    const newState = !showRampUp;
    setShowRampUp(newState);
    if (!newState) {
      formik.setFieldValue('mmcFreeUntil', null);
    } else {
      formik.setFieldValue('mmcFreeUntil', moment(new Date()).toISOString());
    }
  };

  const toggleFreeTier = () => {
    const newState = !showFreeTier;
    setShowFreeTier(newState);
    if (!newState) {
      formik.setFieldValue('freeUntil', null);
    } else {
      formik.setFieldValue('freeUntil', moment(new Date()).toISOString());
    }
  };

  const handleDateChangeRampUp = (rampUpDate: any) =>
    formik.setFieldValue('mmcFreeUntil', rampUpDate);

  const handleDateChangeFree = (freeDate: any) =>
    formik.setFieldValue('freeUntil', freeDate);

  const tiers: Record<any, any>[] = useMemo(() => {
    if (productData && formik.values?.productId) {
      const selectedProduct = productData?.getAllProducts.find(
        (product: any) => product.id === formik.values.productId
      );

      if (selectedProduct) {
        return [...selectedProduct.tiers].sort((a: any, b: any) => a.tier - b.tier);
      }
    }
    return [];
  }, [productData, formik.values]);

  return (
    <>
      <Dialog open={showModal} onClose={hideAutoComplete}>
        <DialogTitle>Busca de Impostos</DialogTitle>
        <DialogContent>
          <DialogContentText>Selecione abaixo um imposto pré-definido</DialogContentText>
          <Autocomplete
            onChange={handleChangeAutoComplete}
            getOptionLabel={(option: any) => option.name ?? option.description}
            loading={loadingTaxes}
            options={taxesData?.getAllTaxes ?? []}
            sx={{ width: 300 }}
            renderInput={(params: any) => <TextField {...params} label="Imposto" />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={hideAutoComplete}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ width: '100%', mb: 2, p: 2 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            <Grid container item spacing={2}>
              <Grid container item spacing={2} xs={4}>
                <Divider textAlign="left" sx={{ m: 1, pt: 4 }}>
                  <Typography variant="h5">Contrato</Typography>
                </Divider>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel style={{ marginLeft: '-13px' }} id="productId">
                      Produto
                    </InputLabel>
                    <Select
                      labelId="productId"
                      id="productId"
                      value={formik.values.productId ?? ''}
                      label="productId"
                      name="productId"
                      variant="standard"
                      disabled={!isAdmin}
                      onChange={formik.handleChange}
                      error={formik.touched.productId && Boolean(formik.errors.productId)}
                    >
                      {productData?.getAllProducts?.map((item: Record<any, any>) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{formik.errors.productId}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel shrink style={{ marginBottom: -25, fontSize: '17px' }}>
                    Tier
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Tier"
                    name="product_tier"
                    variant="standard"
                    onChange={formik.handleChange}
                    disabled={!isAdmin}
                    value={formik.values.product_tier ?? ''}
                  >
                    {tiers.map(({ tier, description }) => (
                      <MenuItem value={tier} key={description || getTierLabel(tier)}>
                        <Typography>{description || getTierLabel(tier)}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel
                    shrink
                    style={{ marginBottom: -25, fontSize: '22px' }}
                    id="partnerId"
                  >
                    Partner
                  </InputLabel>
                  {partnerData && (
                    <Select
                      fullWidth
                      labelId="partnerId"
                      id="partnerId"
                      disabled={!isAdmin}
                      value={formik.values.partnerId ?? ''}
                      label="partnerId"
                      name="partnerId"
                      variant="standard"
                      onChange={formik.handleChange}
                      error={formik.touched.partnerId && Boolean(formik.errors.partnerId)}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        classes: { paper: 'menu-paper' },
                        MenuListProps: {
                          style: { maxHeight: 192, overflow: 'auto' },
                        },
                        style: { width: 200 },
                      }}
                    >
                      {sortPartner(partnerData.getAllPartners)}
                    </Select>
                  )}

                  <FormHelperText>{formik.errors.partnerId}</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel style={{ marginLeft: '-13px' }} id="sellerId">
                      Vendedor
                    </InputLabel>
                    <Select
                      labelId="sellerId"
                      id="sellerId"
                      disabled={!isAdmin}
                      value={formik.values.sellerId ?? ''}
                      label="sellerId"
                      name="sellerId"
                      variant="standard"
                      onChange={formik.handleChange}
                      error={formik.touched.sellerId && Boolean(formik.errors.sellerId)}
                    >
                      {usersData?.getAllUsers?.map((item: Record<any, any>) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.firstName} {item.lastName}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{formik.errors.sellerId}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              {(isOwner(data?.getContractById?.sellerId) || isAdmin) && (
                <Grid container item spacing={2} xs={4} alignSelf="start">
                  <Divider textAlign="left" sx={{ m: 1, pt: 4 }}>
                    <Typography variant="h5">Pagamento</Typography>
                  </Divider>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel style={{ marginLeft: '-13px' }} id="paymentType">
                        Pagamento
                      </InputLabel>
                      <Select
                        labelId="paymentType"
                        id="paymentType"
                        disabled={!isAdmin}
                        value={formik.values.paymentType ?? ''}
                        label="paymentType"
                        name="paymentType"
                        variant="standard"
                        onChange={(value) => formik.handleChange(value)}
                        error={
                          formik.touched.paymentType && Boolean(formik.errors.paymentType)
                        }
                      >
                        {['BOLETO ITAÚ', 'BOLETO BRADESCO'].map((item: string) => (
                          <MenuItem value={item} key={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{formik.errors.paymentType}</FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                      mt: '-25px',
                    }}
                    xs={12}
                  >
                    <SelectDueDate
                      value={formik.values.dueDates}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.dueDates)}
                      name="dueDates"
                      disabled={!isAdmin}
                    />{' '}
                  </Grid>
                </Grid>
              )}
              <Grid container item spacing={2} xs={4} alignSelf="start">
                <Divider textAlign="left" sx={{ m: 1, pt: 4 }}>
                  <Typography variant="h5">Cotação</Typography>
                </Divider>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel style={{ marginLeft: '-11px' }} id="dollarType">
                      Dollar
                    </InputLabel>
                    <Select
                      labelId="dollarType"
                      id="dollarType"
                      disabled={!isAdmin}
                      value={formik.values.dollarType ?? ''}
                      label="Dollar"
                      name="dollarType"
                      variant="standard"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.dollarType && Boolean(formik.errors.dollarType)
                      }
                    >
                      {['PTAX', 'LPTAX', 'FIXED'].map((item: string) => (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{formik.errors.partnerId}</FormHelperText>
                  </FormControl>
                </Grid>
                {formik.values.dollarType !== 'PTAX' && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      autoFocus
                      variant="standard"
                      name="dollarValue"
                      label="Valor"
                      disabled={!isAdmin}
                      type="number"
                      value={formik.values.dollarValue ?? ''}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.dollarValue)}
                      helperText={formik.errors.dollarValue}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Stack alignItems="center" spacing={2} direction="row">
                    <Checkbox
                      checked={showRampUp}
                      onChange={toggleRampUp}
                      disabled={!isAdmin}
                    />
                    Ramp-up
                    {showRampUp && (
                      <YearMonthPicker
                        disabled={!isAdmin}
                        label="Ramp-up"
                        onChange={handleDateChangeRampUp}
                        value={formik.values.mmcFreeUntil}
                      />
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Checkbox
                      checked={showFreeTier}
                      onChange={toggleFreeTier}
                      disabled={!isAdmin}
                    />
                    Gratuidade
                    {showFreeTier && (
                      <YearMonthPicker
                        label="Gratuidade"
                        onChange={handleDateChangeFree}
                        value={formik.values.freeUntil}
                        disabled={!isAdmin}
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Grid item container spacing={4}>
                {formik.values.taxes.length === 0 ? (
                  <Grid item xs={12}>
                    <Typography variant="h4">
                      Impostos
                      <Typography variant="body1">Não há impostos aplicados</Typography>
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Typography variant="h4" sx={{ marginBottom: '20px' }}>
                      Impostos
                    </Typography>
                    {formik.values.taxes.map((tax: any, index: number) => (
                      <Grid
                        item
                        xs={12}
                        sx={{ display: 'flex', alignItems: 'flex-end' }}
                        key={`tax_${index}`}
                        container
                        spacing={2}
                      >
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            autoFocus
                            variant="standard"
                            name={`taxes.[${index}].name`}
                            label="Nome do Imposto"
                            value={formik.values.taxes?.[index]?.name ?? ''}
                            onChange={formik.handleChange}
                            error={Boolean(formik.errors.taxes)}
                            helperText={formik.errors.taxes}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            autoFocus
                            variant="standard"
                            name={`taxes.[${index}].description`}
                            label="Descrição do Imposto"
                            value={formik.values.taxes?.[index]?.description ?? ''}
                            onChange={formik.handleChange}
                            error={Boolean(formik.errors.taxes)}
                            helperText={formik.errors.taxes}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <FormControl fullWidth>
                            <InputLabel
                              sx={{ transform: 'translate(0px,0px) scale(0.75)' }}
                              id="typel"
                            >
                              Tipo do Imposto
                            </InputLabel>
                            <Select
                              labelId="type"
                              label="Tipo do Imposto"
                              name={`taxes.[${index}].type`}
                              variant="standard"
                              inputProps={{
                                name: 'type',
                              }}
                              value={formik.values.taxes?.[index]?.type ?? ''}
                              onChange={formik.handleChange}
                              error={Boolean(formik.errors.taxes)}
                            >
                              <MenuItem value="fixed">Fixo</MenuItem>
                              <MenuItem value="percentage">Por Porcentagem</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                          <TextField
                            fullWidth
                            autoFocus
                            type="number"
                            variant="standard"
                            name={`taxes.[${index}].value`}
                            label="Valor"
                            disabled={!isAdmin}
                            value={formik.values.taxes?.[index]?.value ?? ''}
                            onChange={formik.handleChange}
                            error={Boolean(formik.errors.taxes)}
                            helperText={formik.errors.taxes}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Button
                            size="large"
                            onClick={removeTax(index)}
                            variant="contained"
                          >
                            -
                          </Button>
                        </Grid>
                        <Grid item xs={2} />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
              <Grid item container spacing={4}>
                {formik.values.credit.map((c: any, index: number) => (
                  <Grid item xs={12} key={`credit_${index}`} container spacing={2}>
                    <Grid item xs={1}>
                      <TextField
                        fullWidth
                        autoFocus
                        variant="standard"
                        type="number"
                        name={`credit.[${index}].value`}
                        label="Valor"
                        disabled={!isAdmin}
                        value={c.value ?? ''}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.credit)}
                        helperText={formik.errors.credit}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <FormControl fullWidth>
                        <InputLabel id="creditTypeLabel">Modelo</InputLabel>
                        <Select
                          labelId="type"
                          label="Tipo do Imposto"
                          name={`credit.[${index}].creditType`}
                          variant="standard"
                          value={formik.values.credit?.[index]?.creditType ?? ''}
                          onChange={(value) => formik.handleChange(value)}
                          error={Boolean(formik.errors.credit)}
                        >
                          <MenuItem value="CREDIT">CREDITO</MenuItem>
                          <MenuItem value="DISCOUNT">DESCONTO</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      <FormControl fullWidth>
                        <InputLabel id="valueModelLabel">Cobrança</InputLabel>
                        <Select
                          labelId="valueModel"
                          label="Tipo do Imposto"
                          name={`credit.[${index}].valueModel`}
                          variant="standard"
                          value={formik.values.credit?.[index]?.valueModel ?? ''}
                          onChange={(value) => formik.handleChange(value)}
                          error={Boolean(formik.errors.credit)}
                        >
                          <MenuItem value="FIXED">FIXO</MenuItem>
                          <MenuItem value="PERCENT">PORCENTO</MenuItem>
                          <MenuItem value="LIMITED">LIMITADO</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      <FormControl fullWidth>
                        <InputLabel id="currency">Moeda</InputLabel>
                        <Select
                          labelId="currency"
                          label="Moeda"
                          name={`credit.[${index}].currency`}
                          variant="standard"
                          value={formik.values.credit?.[index]?.currency ?? ''}
                          onChange={(value) => formik.handleChange(value)}
                          error={Boolean(formik.errors.credit)}
                        >
                          <MenuItem value="BRL">R$ - BRL</MenuItem>
                          <MenuItem value="USD">US$ - USD</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      <FormControl fullWidth>
                        <InputLabel id="currencyType">Cobrança Moeda</InputLabel>
                        <Select
                          labelId="currencyType"
                          label="Tipo do Imposto"
                          name={`credit.[${index}].currencyType`}
                          variant="standard"
                          value={formik.values.credit?.[index]?.currencyType ?? ''}
                          onChange={(value) => formik.handleChange(value)}
                          error={Boolean(formik.errors.credit)}
                        >
                          <MenuItem value={formik.values.dollarType}>VIGENTE</MenuItem>
                          <MenuItem value="PTAX">PTAX</MenuItem>
                          <MenuItem value="LPTAX">LPTAX</MenuItem>
                          <MenuItem value="FIXED">FIXED</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      <TextField
                        fullWidth
                        autoFocus
                        variant="standard"
                        type="number"
                        name={`credit.[${index}].currencyValue`}
                        label="Valor da Moeda"
                        InputProps={{ inputProps: { min: 0, max: 99 } }}
                        value={formik.values.credit?.[index]?.currencyValue ?? ''}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.currencyValue)}
                        helperText={formik.errors.currencyValue}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <TextField
                        fullWidth
                        autoFocus
                        variant="standard"
                        type="number"
                        name={`credit.[${index}].remainingMonths`}
                        label="Meses Restantes"
                        InputProps={{ inputProps: { min: 0, max: 99 } }}
                        value={formik.values.credit?.[index]?.remainingMonths ?? ''}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.remainingMonths)}
                        helperText={formik.errors.remainingMonths}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        autoFocus
                        variant="standard"
                        name={`credit.[${index}].description`}
                        label="Descrição"
                        value={formik.values.credit?.[index]?.description ?? ''}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.credit)}
                        helperText={formik.errors.credit}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formik.values.credit?.[index]?.active}
                            onChange={() => handleSwitch(index)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label="ATIVO"
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                        size="large"
                        onClick={removeDiscount(index)}
                        variant="contained"
                      >
                        ✗
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={2}
              xs={12}
              justifyContent="flex-end"
              sx={{ position: 'inherit' }}
            >
              {isAdmin && (
                <ActionButton
                  data-testid="create-tax-button"
                  title="Criar novo Imposto"
                  onClick={handleAutoComplete}
                  typography={<MonetizationOn fontSize="large" />}
                />
              )}
            </Grid>
            <Grid item container spacing={2} xs={12} justifyContent="flex-end">
              <Grid item>
                <Button size="large" onClick={navigateBack} data-testid="cancel-contract">
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                {isAdmin && (
                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    data-testid="save-contract-button"
                  >
                    Salvar
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default ContractForm;
