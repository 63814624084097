import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import Header from '../../../components/header';
import productSchema from './schema';
import LoadingScreen from '../../../components/loadingScreen';
import {
  GET_ALL_PRODUCTS,
  GET_PRODUCT_BY_ID,
  UPDATE_PRODUCT,
  CREATE_PRODUCT,
} from '../../../gql/products';
import { GET_ALL_MANUFACTURERS } from '../../../gql/manufacturers';
import Button from '@mui/material/Button';
import useSnackbar from '../../../hooks/useSnackbar';
import { getTenantId } from '../../../services/tenancy';
import { SelectChangeEvent } from '@mui/material';
import { getTierLabel } from '../../../utils/tiers';
import usePermissions from '../../../hooks/usePermissions';

const ProductForm = () => {
  const { id } = useParams();
  const isCreate = id === 'create';
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [activeTier, setActiveTier] = useState<string>('0');
  const [contract, setContract] = useState();
  const { data: manufacturersData, loading: loadingManufacturers } =
    useQuery(GET_ALL_MANUFACTURERS);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) =>
    setActiveTier(newValue.toString());

  const handleFileSubmit = (event: any) => setContract(event.target.files[0]);

  const handleAddTier = () => {
    const { tiers } = formik.values;
    const tier = tiers.length;
    formik.setFieldValue('tiers', [
      ...tiers,
      {
        tier,
        mmc: '0',
        tenantId: getTenantId(),
      },
    ]);

    setActiveTier(String(tier));
  };

  const handleAddResource = () => {
    const { resources, tiers } = formik.values;
    formik.setFieldValue('resources', [
      ...resources,
      {
        name: '',
        sku: '',
        tenantId: getTenantId(),
        prices: tiers.map(({ tier }: { tier: number }) => ({
          tier,
          tenantId: getTenantId(),
        })),
      },
    ]);
  };

  const handleRemoveResource = (resourceIndex: number) => () => {
    const { resources } = formik.values;
    const newResources = [...resources];
    newResources.splice(resourceIndex, 1);
    formik.setFieldValue('resources', newResources);
  };

  const [mutateProduct] = useMutation(isCreate ? CREATE_PRODUCT : UPDATE_PRODUCT, {
    refetchQueries: [{ query: GET_ALL_PRODUCTS }],
  });
  const [getCurrentProduct, { loading, data, error }] = useLazyQuery(GET_PRODUCT_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  const navigateBack = () => navigate('/dashboard/invoices');

  const handleSubmit = (values: Record<any, any>) => {
    snackbar.setGlobalLoading(true);
    snackbar.sendAlert({ level: 'info', message: 'Salvando Dados...' });

    const input: Record<string, any> = { tenantId: getTenantId() };
    Object.keys(productSchema.fields).map((key) => {
      if (Object.hasOwn(values, key)) input[key] = values[key];
      return null;
    });
    const variables: Record<any, any> = { input };
    if (isCreate) {
      variables.file = contract;
    }

    mutateProduct({ variables })
      .then(() =>
        snackbar.sendAlert({ level: 'success', message: 'Dados salvos com sucesso ' })
      )
      .catch((er) => {
        snackbar.sendAlert({ level: 'error', message: 'Erro Salvando dados' });
        console.error(er);
      })
      .finally(() => {
        snackbar.setGlobalLoading(false);
        navigateBack();
      });
  };

  const formik = useFormik({
    initialValues: data?.getProductById ?? {
      tiers: [],
      resources: [],
    },
    validationSchema: productSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!isCreate) getCurrentProduct({ variables: { id } });
  }, [isCreate]);

  const handleManufacturer = (event: SelectChangeEvent) => {
    formik.setFieldValue('manufacturerId', event.target.value);
    formik.setFieldValue('manufacturer', { id: event.target.value });
  };
  const tiers = useMemo(
    () => data?.getProductById?.tiers.sort((a: { tier: number; }, b: { tier: number; }) => a.tier - b.tier) || [],
    [data]
  );

  const { isSalesAdmin } = usePermissions();
  const adminPermissions = isSalesAdmin;

  if (error) console.error(error);

  return (
    <>
      {(loading || loadingManufacturers) && <LoadingScreen />}
      <Header title={`${isCreate ? 'Criar' : 'Editar'} Produto`} />
      <Paper sx={{ width: '100%', mb: 2, p: 2 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            <Grid container item spacing={4}>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  autoFocus
                  variant="standard"
                  label="Nome"
                  name="name"
                  value={formik.values.name ?? ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.name)}
                  helperText={formik.errors.name}
                  disabled={!adminPermissions}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <InputLabel>Fabricante</InputLabel>
                  <Select
                    labelId="manufacturer"
                    id="manufacturer"
                    value={formik.values.manufacturer?.id ?? ''}
                    label="manufacturer"
                    name="manufacturer"
                    variant="standard"
                    onChange={handleManufacturer}
                    error={
                      formik.touched.manufacturer && Boolean(formik.errors.manufacturer)
                    }
                    disabled={!adminPermissions}
                  >
                    {manufacturersData?.getAllManufacturers?.map(
                      (item: Record<any, any>) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                {isSalesAdmin ? (
                  <>
                    <InputLabel>Modelo de Contrato</InputLabel>
                    <TextField
                      fullWidth
                      variant="standard"
                      label="Attached"
                      type="file"
                      onChange={handleFileSubmit}
                    />
                  </>
                ) : null}
              </Grid>
            </Grid>

            <Grid item container xs={12}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={activeTier}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                      onChange={handleTabChange}
                      aria-label="Tiers"
                      value={activeTier}
                    >
                      {tiers?.map((tier: any, tierValue: number) => (
                        <Tab
                          key={tier?.description || getTierLabel(tierValue)}
                          label={tier?.description || getTierLabel(tierValue)}
                          value={String(tierValue)}
                        />
                      ))}
                      {isSalesAdmin ? (
                        <Button
                          fullWidth
                          color="primary"
                          variant="contained"
                          onClick={handleAddTier}
                        >
                          +
                        </Button>
                      ) : null}
                    </TabList>
                  </Box>
                </TabContext>
                <Grid item xs={12}>
                  {tiers?.[activeTier] && (
                    <Box sx={{ p: 0.5, pt: 2 }}>
                      <TextField
                        fullWidth
                        autoFocus
                        variant="standard"
                        type="number"
                        label="MMC"
                        name={`tiers.${activeTier}.mmc`}
                        value={tiers[activeTier].mmc}
                        onChange={formik.handleChange}
                        disabled={!adminPermissions}
                      />

                      <Grid
                        item
                        container
                        xs={12}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ pt: 1 }}
                      >
                        {(formik.values?.resources ?? []).map(
                          (service: Record<any, any>, index: number) => {
                            const currentPricingIndex = service.prices.findIndex(
                              ({ tier }: { tier: number }) => tier === Number(activeTier)
                            );

                            return (
                              <Fragment key={`resource-${service?.id ?? index}`}>
                                <Grid item xs={2}>
                                  <TextField
                                    fullWidth
                                    variant="standard"
                                    label="Nome"
                                    name={`resources.${index}.name`}
                                    value={formik.values.resources[index].name}
                                    onChange={formik.handleChange}
                                    disabled={!adminPermissions}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <TextField
                                    fullWidth
                                    variant="standard"
                                    label="SKU"
                                    name={`resources.${index}.sku`}
                                    value={formik.values.resources[index].sku}
                                    onChange={formik.handleChange}
                                    disabled={!adminPermissions}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <TextField
                                    fullWidth
                                    variant="standard"
                                    label="Unidade"
                                    name={`resources.${index}.prices.${currentPricingIndex}.unit`}
                                    value={
                                      formik.values.resources[index]?.prices?.[
                                        currentPricingIndex
                                      ]?.unit ?? ''
                                    }
                                    onChange={formik.handleChange}
                                    disabled={!adminPermissions}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <TextField
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                    label="Valor De Custo"
                                    name={`resources.${index}.prices.${currentPricingIndex}.unitCostPrice`}
                                    value={
                                      formik.values.resources[index]?.prices?.[
                                        currentPricingIndex
                                      ]?.unitCostPrice ?? ''
                                    }
                                    onChange={formik.handleChange}
                                    disabled={!adminPermissions}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <TextField
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                    label="Valor De Venda"
                                    name={`resources.${index}.prices.${currentPricingIndex}.unitSalePrice`}
                                    value={
                                      formik.values.resources[index]?.prices?.[
                                        currentPricingIndex
                                      ]?.unitSalePrice ?? ''
                                    }
                                    onChange={formik.handleChange}
                                    disabled={!adminPermissions}
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="unitCurrency-label">Moeda</InputLabel>
                                    <Select
                                      label="Moeda"
                                      name={`resources.${index}.prices.${currentPricingIndex}.unitCurrency`}
                                      value={
                                        formik.values.resources[index]?.prices?.[
                                          currentPricingIndex
                                        ]?.unitCurrency
                                      }
                                      onChange={formik.handleChange}
                                      disabled={!adminPermissions}
                                    >
                                      <MenuItem value="BRL">R$ BRL</MenuItem>
                                      <MenuItem value="USD">US$ USD</MenuItem>
                                      <MenuItem value="EUR">€ EUR</MenuItem>
                                      <MenuItem value="GBP">£ GBP</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                  {isSalesAdmin ? (
                                    <Button
                                      fullWidth
                                      color="error"
                                      variant="outlined"
                                      onClick={handleRemoveResource(index)}
                                    >
                                      X
                                    </Button>
                                  ) : null}
                                </Grid>
                              </Fragment>
                            );
                          }
                        )}
                        <Grid item>
                          {isSalesAdmin ? (
                            <Button
                              fullWidth
                              color="primary"
                              variant="outlined"
                              onClick={handleAddResource}
                            >
                              Adicionar Resource
                            </Button>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Grid>
              </Box>
            </Grid>

            <Grid item container spacing={2} xs={12} justifyContent="flex-end">
              <Grid item>
                <Button size="large" onClick={navigateBack}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                {isSalesAdmin ? (
                  <Button variant="contained" size="large" type="submit">
                    Salvar
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default ProductForm;
