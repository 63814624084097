import React from 'react';
import Paper from '@mui/material/Paper';
import { useMutation, useQuery } from '@apollo/client';
import GenericTable from '../../components/genericTable';
import Header from '../../components/header';
import { useNavigate } from 'react-router-dom';
import { GET_ALL_USERS, REMOVE_USER } from '../../gql/users';
import ActionButton from '../../components/actionButton';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import Group from '../group/index';
import ButtonGroup from '../../components/buttonGroup';
import PartnersRoute from '../partners/index';
import { Button } from '@mui/material';
import useLocationName from '../../hooks/useLocationName';
import usePermissions from '../../hooks/usePermissions';
import { Role as userRoles } from '../../utils/roles';

const columnsOptions = {
  id: {
    hide: true,
  },
  firstName: {
    headerName: 'Nome',
  },
  lastName: {
    headerName: 'Sobrenome',
  },
  email: {
    headerName: 'E-mail',
  },
  role: {
    headerName: 'Grupo',
    valueGetter: ({ value }: Record<any, any>) => userRoles[value],
  },
  partner: {
    headerName: 'Parceiro',
    valueGetter: ({ value }: Record<any, any>) => value.tradingName,
  },
};

const tabNames: Record<string, string> = {
  users: 'Usuários',
  groups: 'Grupos',
  partners: 'Parceiros',
};

const UsersRoute = () => {
  const navigate = useNavigate();
  const currentTab = useLocationName();
  const { isAdmin } = usePermissions();

  const { data, loading } = useQuery(GET_ALL_USERS);
  const [deleteUser] = useMutation(REMOVE_USER, { refetchQueries: [GET_ALL_USERS] });

  const handleNewUser = () => navigate('create');
  const handleEditUser = ({ id = '' }: { id?: string }) => navigate(id);
  const handleDeleteUser = ({ id = '' }) => deleteUser({ variables: { id } });

  const handleChangeTabs = (newTab: React.SetStateAction<string>) => () =>
    navigate(`/dashboard/${newTab}`);

  const defaultTab = isAdmin ? currentTab : 'partners';

  return (
    <>
      <Header title={tabNames[defaultTab]} />

      <ButtonGroup variant="contained" size="large">
        {isAdmin ? (
          <>
            <Button
              className={currentTab === 'users' ? 'button-group-selected' : ''}
              onClick={handleChangeTabs('users')}
            >
              Usuários
            </Button>
            <Button
              onClick={handleChangeTabs('groups')}
              className={currentTab === 'groups' ? 'button-group-selected' : ''}
            >
              Grupos
            </Button>
            <Button
              onClick={handleChangeTabs('partners')}
              className={currentTab === 'partners' ? 'button-group-selected' : ''}
            >
              Parceiros
            </Button>
          </>
        ) : null}
      </ButtonGroup>

      {defaultTab === 'users' && isAdmin && (
        <Paper sx={{ width: '100%', mb: 2 }}>
          <GenericTable
            data={data?.getAllUsers}
            loading={loading}
            columnOptions={columnsOptions}
            onEdit={handleEditUser}
            onDelete={handleDeleteUser}
          />
        </Paper>
      )}

      {defaultTab === 'partners' && <PartnersRoute />}

      {isAdmin ? (
        <ActionButton
          title="Criar um novo usuário"
          typography={<PeopleAlt />}
          onClick={handleNewUser}
        />
      ) : null}

      {defaultTab === 'groups' && (
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Group />
        </Paper>
      )}
    </>
  );
};

export default UsersRoute;
