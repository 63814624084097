import MuiButtonGroup from '@mui/material/ButtonGroup';
import styled from 'styled-components';

const ButtonGroup = styled(MuiButtonGroup)`
  && {
    height: 25px;
    box-shadow: none;
    
    button {
      font-size: 11px;
      color: #000;
      background-color: #fff;
      }
    
    .button-group-selected {
      background-color: ${({ theme }) => theme?.palette?.primary.main};
      color: #fff;
    }
  }
`;

export default ButtonGroup;
