// @TODO: refatorar tabs de index-based para symbol-based
import React, { useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import { useApolloClient, useQuery } from '@apollo/client';
import useAuthToken from '../../hooks/useAuthToken';
import { GET_USER_PROFILE } from '../../gql/users';
import stockLogo from '../../assets/images/stock_logo.png';
import Newspaper from '@mui/icons-material/Newspaper';
import Article from '@mui/icons-material/Article';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import Handshake from '@mui/icons-material/Handshake';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import PointOfSale from '@mui/icons-material/PointOfSale';
import Person from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import HeaderMenuItem from './components/headerMenuItem';
import { MenuItemI } from '../../types/menu';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SettingsIcon from '@mui/icons-material/Settings';
import AlertsMenu from '../alertsMenu';
import Popover from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import usePermissions from '../../hooks/usePermissions';

const ActivityMenu: React.FC = () => {
  const { isSalesAdmin, isSalesUser } = usePermissions();

  const menus: MenuItemI[] = [
    {
      name: 'Inicio',
      icon: <HomeIcon fontSize="large" color="primary" />,
      location: '/dashboard',
      menus: [],
    },
    // @ts-ignore
    ...(isSalesAdmin
      ? [
          {
            name: 'Marketing',
            icon: <Newspaper fontSize="large" color="primary" />,
            disabled: true,
            menus: [
              {
                name: 'Posts',
                icon: <Article fontSize="medium" color="primary" />,
                location: 'posts',
                entity: 'Post',
                disabled: true,
              },
            ],
          },
        ]
      : []),
    {
      name: 'Administração',
      icon: <PeopleAlt fontSize="large" />,
      // @ts-ignore
      menus: [
        {
          name: 'Cadastros',
          icon: <Person fontSize="medium" color="primary" />,
          location: 'users',
          entity: 'User',
        },
        // @ts-ignore
        ...(isSalesAdmin
          ? [
              {
                name: 'Calendario',
                icon: <CalendarMonth fontSize="medium" color="primary" />,
                location: 'calendar',
                entity: 'Calendar',
              },
            ]
          : []),
        // @ts-ignore
        ...(isSalesAdmin
          ? [
              {
                name: 'Dashboards',
                icon: <QueryStatsIcon fontSize="medium" color="primary" />,
                location: 'dashboards',
                entity: 'Dashboards',
              },
            ]
          : []),
      ],
    },
    {
      name: 'Negocios',
      icon: <Handshake fontSize="large" />,
      menus: [
        {
          name: 'Financeiro',
          icon: <PointOfSale fontSize="medium" color="primary" />,
          location: 'invoices',
          entity: 'Invoice',
        },
        // @ts-ignore
        ...(isSalesAdmin
          ? [
              {
                name: 'Avaliações',
                icon: <AssignmentTurnedInIcon fontSize="medium" color="primary" />,
                location: 'assessments',
                entity: 'assessments',
              },
            ]
          : []),
      ],
    },
  ];
  const { data } = useQuery(GET_USER_PROFILE);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeSection, setActiveSection] = useState(0);

  const regexRoutes = {
    users: /^\/dashboard\/users\/(.*)$/,
    groups: /^\/dashboard\/groups\/(.*)$/,
    partners: /^\/dashboard\/partners\/(.*)$/,
    taxes: /^\/dashboard\/taxes\/(.*)$/,
    dashboards: /^\/dashboard\/dashboards\/(.*)$/,
    invoices: /^\/dashboard\/invoices\/(.*)$/,
    contracts: /^\/dashboard\/contracts\/(.*)$/,
    products: /^\/dashboard\/products\/(.*)$/,
    manufacturers: /^\/dashboard\/manufacturers\/(.*)$/,
    wallets: /^\/dashboard\/wallets\/(.*)$/,
    assessments: /^\/dashboard\/assessments\/(.*)$/,
    alerts: /^\/dashboard\/alerts\/(.*)$/,
  };

  useEffect(() => {
    const getActiveSectionFromRoute = () => {
      if (!location || !location.pathname) {
        return;
      }

      if (
        location.pathname === '/dashboard/users' ||
        regexRoutes.users.test(location.pathname) ||
        location.pathname === '/dashboard/groups' ||
        regexRoutes.groups.test(location.pathname) ||
        location.pathname === '/dashboard/partners' ||
        regexRoutes.partners.test(location.pathname) ||
        location.pathname === '/dashboard/calendar' ||
        location.pathname === '/dashboard/dashboards'
      ) {
        setActiveSection(isSalesAdmin ? 2 : 1);
      } else if (
        location.pathname === '/dashboard/invoices' ||
        regexRoutes.invoices.test(location.pathname) ||
        location.pathname === '/dashboard/contracts' ||
        regexRoutes.contracts.test(location.pathname) ||
        location.pathname === '/dashboard/products' ||
        location.pathname === '/dashboard/taxes' ||
        regexRoutes.taxes.test(location.pathname) ||
        regexRoutes.products.test(location.pathname) ||
        location.pathname === '/dashboard/manufacturers' ||
        regexRoutes.manufacturers.test(location.pathname) ||
        location.pathname === '/dashboard/wallets' ||
        regexRoutes.wallets.test(location.pathname) ||
        location.pathname === '/dashboard/assessments' ||
        regexRoutes.assessments.test(location.pathname) ||
        regexRoutes.alerts.test(location.pathname) ||
        location.pathname === '/dashboard/alerts'
      ) {
        setActiveSection(isSalesAdmin ? 3 : 2);
      } else if (location.pathname.startsWith('/dashboards/users')) {
        setActiveSection(isSalesUser ? 3 : 2);
      } else {
        setActiveSection(0);
      }
    };

    getActiveSectionFromRoute();
  }, [location.pathname]);

  const handleTabChange = (event: any, newValue: any) => {
    setActiveSection(newValue);
  };

  const [, , removeAuthToken] = useAuthToken();
  const apolloClient = useApolloClient();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'alerts-popover' : undefined;

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleHome = () => navigate('/dashboard');

  const handleCloseUserMenu = () => setAnchorElUser(null);

  const handleProfile = () => {
    navigate('/dashboard/profile');
    handleCloseUserMenu();
  };

  const handleLogout = () => {
    removeAuthToken();
    apolloClient.clearStore();
    navigate('/login');
  };

  return (
    <AppBar
      color="transparent"
      position="fixed"
      sx={{ height: '80px', backgroundColor: '#fff' }}
    >
      <Toolbar variant="dense" sx={{ justifyContent: 'space-between', height: '80px' }}>
        <Box sx={{ cursor: 'pointer' }} onClick={handleHome}>
          <img src={stockLogo} alt="" style={{ maxHeight: '32px', cursor: 'pointer' }} />
        </Box>
        <Box>
          <Tabs
            value={activeSection}
            sx={{ paddingTop: '7px' }}
            onChange={handleTabChange}
          >
            {menus.map((menu) => (
              <HeaderMenuItem item={menu} key={menu.name} />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <IconButton color="inherit" style={{ width: 45, height: 45 }} sx={{ mt: 1 }}>
            <Badge badgeContent={0} color="error">
              <SettingsIcon sx={{ color: '#000' }} />
            </Badge>
          </IconButton>
          <IconButton color="inherit" style={{ width: 45, height: 45 }} sx={{ mt: 1 }}>
            <Badge badgeContent={0} color="error">
              <MailIcon sx={{ color: '#000' }} />
            </Badge>
          </IconButton>
          <IconButton
            color="inherit"
            style={{ width: 45, height: 45 }}
            sx={{ mt: 1 }}
            onClick={handleClick}
          >
            <Badge badgeContent={0} color="error">
              <NotificationsIcon sx={{ color: '#000' }} />
            </Badge>
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Grid sx={{ width: '450px', height: '395px' }}>
              <AlertsMenu />
            </Grid>
          </Popover>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              onClick={handleOpenUserMenu}
            >
              <Avatar>{data?.me?.firstName?.[0]}</Avatar>
            </IconButton>
            <Menu
              sx={{ mt: '25px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleProfile}>
                <Typography textAlign="center">Profile</Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default ActivityMenu;
