import { gql } from '@apollo/client';


export const GET_ALL_PRODUCTS_NAMES = gql`
  query getAllProducts{
    getAllProducts {
      id
      name
      tiers {
        tier
        description
      }
    }
  }`;

export const GET_PRODUCTS = gql`
  query getProducts{
      getAllProducts {
          id
          name
          manufacturer{
              id
              name
          }
          tiers {
              mmc
              tier
          }
      }
  }`;
export const GET_ALL_PRODUCTS = gql`
  query getAllProducts{
    getAllProducts {
      id
      name
      manufacturer{
        id
        name
      }
      resources {
        id
        name
        productId
        sku
        prices {
          id
          productResourceId
          unit
          unitCostPrice
          unitSalePrice
          unitCurrency
          tier
          tenantId
        }
      }
      tiers {
        id
        mmc
        productId
        tenantId
        tier
      }
    }
  }`;

export const CREATE_PRODUCT = gql`
  mutation createProduct ($file: Upload!, $input: CreateProductInput!) {
    createProduct (file: $file, input: $input) {
      id
    }
  }`;

export const GET_PRODUCT_BY_ID = gql`
  query getProductById($id: String!) {
    getProductById(id: $id) {
      id
      manufacturer{
        id
        name
      },
      manufacturerId,
      name
      resources {
        id
        name
        productId
        sku
        prices {
          id
          productResourceId
          unit
          unitCostPrice
          unitSalePrice
          unitCurrency
          tier
          tenantId
        }
        tenantId
      },
      tiers {
        id
        description
        mmc
        productId
        tenantId
        tier
      }
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
    }
  }
`;

export const REMOVE_PRODUCTS = gql`
  mutation removeProduct ($id: String!) {
    removeProduct (id: $id)
  }
`;
