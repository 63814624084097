import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

export const NavbarGrid = styled(Grid)`
  background-color: #003d98;
  padding: 5px;
`;

export const Card = styled(Paper)`
  padding: 16px;
  border-radius: 12px;
  border-top: 6px solid #003d98;
  width: 100%;
  height: 100%;
  overflow: auto;
`;


