import React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

type CustomButtonProps = {
  title: string;
  typography: any;
  onClick: () => void;
};

const ActionButton: React.FC<CustomButtonProps> = ({ title, typography, onClick }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
    <Tooltip title={title} placement="left">
      <Button
        variant="contained"
        size="large"
        onClick={onClick}
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 40,
          right: 46,
          borderRadius: '50%',
          alignItems: 'center',
          justifyContent: 'center',
          width: '70px',
          height: '70px',
        }}
      >
        <Typography sx={{ marginTop: 0.4 }}>{typography}</Typography>
      </Button>
    </Tooltip>
  </Box>
);

export default ActionButton;
