import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const PaperAlert = styled(Paper)`
  width: 450px;
  height: 395px;
  overflow-y: hidden;
  -webkit-box-shadow: 0px 3px 15px 2px rgba(117, 117, 117, 1);
  -moz-box-shadow: 0px 3px 15px 2px rgba(117, 117, 117, 1);
  box-shadow: 0px 2px 15px 2px rgba(117, 117, 117, 1);
`;

export const TypographyAlerts = styled(Typography)`
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 10px;
  color: #2d4490;
  cursor: pointer;
`;

export const BoxScrollBar = styled(Box)`
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #848589;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const BoxAlerts = styled(Box)`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 110px;
`;
