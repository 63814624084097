import { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import jwtDecode from 'jwt-decode';


const TOKEN_NAME = 'jwtSession';

/*
const getAbility = () => {
  // console.log('Ability');
  const token: string = Cookies.get('jwtSession') ?? '';
  const decodedToken: JwtDecoded = jwt(token);
  const ability = new Ability();
  ability.update(unpackRules(decodedToken.rules));
  return ability;
};
 */

const useAuthToken = () => {
  const [cookies, setCookie, removeCookie] = useCookies([TOKEN_NAME]);
  const setAuthToken = (authToken: string) => setCookie(TOKEN_NAME, authToken);
  const removeAuthToken = () => removeCookie(TOKEN_NAME);

  const isTokenValid = useMemo(() => {
    const currentToken = cookies[TOKEN_NAME];
    if (!currentToken) return false;

    const { exp } = jwtDecode<Record<string, number>>(currentToken);
    const currentDate = new Date();

    // JWT exp is in seconds
    return exp * 1000 >= currentDate.getTime();
  }, [cookies]);


  return [cookies[TOKEN_NAME], setAuthToken, removeAuthToken, isTokenValid];
};

export default useAuthToken;
