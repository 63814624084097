import React, { useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import { LOGIN } from '../../../gql/login';
import LoadingScreen from '../../../components/loadingScreen';
import defaultAvatar from '../../../assets/images/stock.png';
import { LoginAvatar } from '../styles';
import useAuthToken from '../../../hooks/useAuthToken';

interface loginFormProps {
  toggleReset: () => void
}

const LoginForm = ({ toggleReset }: loginFormProps) => {
  const [, setAuthToken] = useAuthToken();
  const [login, { data, loading, error }] = useMutation(LOGIN);

  // avatar should be retrieved from the tenant object in the future;
  const avatar = useMemo(() => defaultAvatar, []);

  useEffect(() => {
    if (data?.login) {
      const { access_token: token } = data?.login ?? {};
      setAuthToken(token);
    }
    if (error) console.error(error);
  }, [data, error]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userCredentials = {
      email: data.get('email'),
      password: data.get('password')
    };

    login({ variables: { userCredentials } });
  };

  return (
    <Grid container alignItems="center" justifyContent="center">
      {loading && <LoadingScreen />}
      <Grid item>
        <LoginAvatar src={avatar} />
      </Grid>
      <Grid item xs={12}>
        <Link
          onClick={toggleReset}
          component="button"
          color="secondary"
          variant="body2"
          underline="none"
        >
          Esqueceu a senha? Clique aqui
        </Link>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            size="large"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            Entrar
          </Button>
          {error?.graphQLErrors &&
            error?.graphQLErrors.map(({ message }) => (
              <Alert severity="error">{message}</Alert>
            ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
