import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { formatBrl, formatUsd } from '../../utils/numbers';

interface GenericTablePriceProps {
  totalValue: number | string;
  subTotal: number | string;
  discounts: number | string;
}

interface ObservationProps {
  invoiceData: Record<string, any>;
  tier: string;
  paymentType: string;
}

const GenericTablePrice = ({
  totalValue,
  discounts,
  subTotal,
}: GenericTablePriceProps) => (
  <Grid item>
    <TableContainer
      component={Paper}
      sx={{
        height: { sm: '30%', md: '100%' },
        [`& .MuiTableCell-root`]: {
          [`@media (max-width: 960px)`]: {
            padding: '4px',
            fontSize: 12,
          },
        },
      }}
    >
      <Table aria-label="caption table">
        <TableRow>
          <TableCell>
            <b>Sub Total</b>
          </TableCell>
          <TableCell align="right">{formatBrl(Number(subTotal))}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <b>Descontos</b>
          </TableCell>
          <TableCell align="right">{formatBrl(Number(discounts))}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <b>Total</b>
          </TableCell>
          <TableCell align="right">{formatBrl(Number(totalValue))}</TableCell>
        </TableRow>
      </Table>
    </TableContainer>
  </Grid>
);

const GenericTableObs = ({ invoiceData, tier, paymentType }: ObservationProps) => (
  <Grid item>
    <Typography sx={{ fontWeight: 'bold', mb: 2, mt: 4 }}>Observações</Typography>
    <TableContainer
      component={Paper}
      sx={{
        height: { sm: '30%', md: '100%' },
        [`& .MuiTableCell-root`]: {
          [`@media (max-width: 960px)`]: {
            padding: '4px',
            fontSize: 10,
          },
        },
      }}
    >
      <Table aria-label="caption table">
        <TableRow>
          <TableCell>
            <b>Tier</b>
          </TableCell>
          <TableCell align="right">{tier}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <b>Forma de Pagamento</b>
          </TableCell>
          <TableCell align="right">{paymentType}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <b>Dólar</b>
          </TableCell>
          <TableCell align="right">
            {formatUsd(invoiceData.dollarRate)} - {invoiceData.dollarType}
          </TableCell>
        </TableRow>

        {invoiceData?.observations?.map((obv: { message: string; type: string }) => (
          <TableRow key={obv.message}>
            <TableCell>
              <b>{obv.type}</b>
            </TableCell>
            <TableCell align="right">{obv.message}</TableCell>
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  </Grid>
);

export { GenericTableObs, GenericTablePrice };
