import React from 'react';
import ActionButton from '../../components/actionButton';
import GroupsIcon from '@mui/icons-material/Groups';
import { useNavigate } from 'react-router-dom';
import GenericTable from '../../components/genericTable';

const Group = () => {
  const navigate = useNavigate();
  const handleNewGroup = () => navigate('editGroup');

  const columnOptions = {
    grupoDeSeguranca: {
      headerName: 'Grupos de Segurança',
      flex: 1,
    },
    descricao: {
      headerName: 'Descrição do Grupo de Segurança',
      flex: 1
    }
  }
  const rows = [
    {
      id: 1,
      grupoDeSeguranca: 'PARTNER_ADMIN',
      descricao: 'Usuário administrador Parceiro',
    },
    {
      id: 2,
      grupoDeSeguranca: 'PARTNER_USER',
      descricao: 'Usuário comum',
    },
    {
      id: 3,
      grupoDeSeguranca: 'MARKETING_ADMIN',
      descricao: 'Usuário administrador Marketing',
    },
    {
      id: 4,
      grupoDeSeguranca: 'MARKETING',
      descricao: 'Usuário comum Marketing',
    },
    {
      id: 5,
      grupoDeSeguranca: 'COMERCIAL_ADMIN',
      descricao: 'Usuário Administrador Comercial',
    },
    {
      id: 6,
      grupoDeSeguranca: 'COMERCIAL',
      descricao: 'Usuário Comum Comercial',
    },
    {
      id: 7,
      grupoDeSeguranca: 'FINANCEIRO_ADMIN',
      descricao: 'Usuário Administrador Financeiro',
    },
    {
      id: 8,
      grupoDeSeguranca: 'FINANCEIRO',
      descricao: 'Usuário Comum Financeiro',
    },
    {
      id: 9,
      grupoDeSeguranca: 'SUPER_ADMIN',
      descricao: 'Usuário Administrador Máximo',
    },
  ];

  return (
    <div style={{ height: 600, width: '100%' }}>
      <GenericTable
        data={rows}
        loading={false}
        columnOptions={columnOptions}
        hideDeleteButton
      />
      <ActionButton
        title="Editar Grupos de segurança"
        typography={<GroupsIcon />}
        onClick={handleNewGroup}
      />
    </div>
  );
};

export default Group;
