import * as yup from 'yup';
import { requiredField } from '../../../utils/forms';

const partnerSchema = yup.object({
  id: yup
    .string()
    .label('id'),
  title: yup
    .string()
    .required(requiredField('Titulo')),
});

export default partnerSchema;
