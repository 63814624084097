import Box from '@mui/material/Box';
import {
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React from 'react';

const CustomToolBar = () => (
  <>
    <Box>
      <GridToolbarQuickFilter
        autoFocus
        toolbarButtonProps={{ variant: 'outlined' }}
        placeholder="Procurar"
        debounceTime={500}
        clearSearchOnClick={false}
        highlightColor="secondary"
        ignoreCase
      />
    </Box>
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  </>
);
export default CustomToolBar;
