export enum Role {
  UNAUTHORIZED,
  PARTNER_USER,
  PARTNER_ADMIN,
  MARKETING_USER,
  MARKETING_ADMIN,
  SALES_USER,
  SALES_ADMIN,
  ACCOUNTING_USER,
  ACCOUNTING_ADMIN,
  ADMIN,
  SUPER_ADMIN,
}

export default {
  Role,
};
