export interface EntityValue {
  id: string,
  [key:string]:string|number
}
export interface TabState {
  value:number;
  updateEntityValue:EntityValue
}
export const initialState:TabState = {
  value: 0,
  updateEntityValue: {
    id: '',
  }
};
const SET_ACTIVITY_TABS = 'partner-portal/tabs/SET_ACTIVITY_TABS';
const SET_UPDATABLE_ENTITY = 'partner-portal/tabs/SET_UPDATABLE_ENTITY';
export function setActivityTab(payload:number) {
  return {
    type: SET_ACTIVITY_TABS,
    payload
  };
}
export function setUpdatableEntity(payload:object) {
  return {
    type: SET_UPDATABLE_ENTITY,
    payload
  };
}
export default function reducer(state = initialState, action: { type: string | string[], payload: object }) {
  switch (action.type) {
    case SET_ACTIVITY_TABS: {
      return {
        ...state,
        value: action.payload
      };
    }
    case SET_UPDATABLE_ENTITY: {
      return {
        ...state,
        updateEntityValue: action.payload
      };
    }

    default:
      return state;
  }
}

export function updateValue(payload: object) {
  return {
    type: SET_ACTIVITY_TABS,
    payload
  };
}
