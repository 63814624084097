import React, { useState } from 'react';
import Header from '../../components/header';
import GenericTable from '../../components/genericTable';
import { Alerts, TypographyDetails, GridMenu } from './style';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { GET_ALL_ALERTS } from '../../gql/alerts';
import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const Alertas = () => {
  const { data, loading } = useQuery(GET_ALL_ALERTS);

  const columnsOptions = {
    id: {
      hide: true,
      hideable: false,
      filterable: false,
    },
    description: {
      headerName: 'Descrição',
      filterable: true,
    },
    level: {
      headerName: 'Descrição',
      filterable: true,
    },
    hash: {
      headerName: 'Hash',
    },
    count: {
      headerName: 'Contador',
      hideable: false,
      filterable: false,
    },
    status: {
      headerName: 'Status',
    },
    lastOccurrence: {
      headerName: 'Data e Hora',
    },
    tenantId: {
      hide: true,
    },
  };
  const [selectedAlert, setSelectedAlert] = useState<any>(null);

  const showInfoAlerts = (row: any) => {
    setSelectedAlert(row.row || null);
  };

  const count = data?.getAllAlerts?.length;

  return (
    <>
      <Header title="Alertas" />
      <Grid sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
        {/* tornar um componente só */}
        <Alerts variant="outlined" severity="error">
          {count} alerta(s) de erro ativo(s)
        </Alerts>
        <Alerts variant="outlined" severity="warning">
          alerta(s) de atenção ativo(s)
        </Alerts>
        <Alerts variant="outlined" severity="info">
          alerta(s) de informação ativo(s)
        </Alerts>
        <Alerts variant="outlined" severity="success">
          alerta de sucesso ativo
        </Alerts>
      </Grid>
      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid
          sx={{
            width: selectedAlert ? 'calc(100% - 420px)' : '100%',
          }}
        >
          <GenericTable
            columnOptions={columnsOptions}
            data={data?.getAllAlerts}
            loading={loading}
            onEdit={showInfoAlerts}
            hide
          />
        </Grid>
        {selectedAlert && (
          <Paper
            sx={{
              marginLeft: '20px',
              width: '420px',
              height: 'auto',
            }}
          >
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                margin: '20px auto',
                gap: 1,
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  paddingLeft: 2,
                  fontWeight: 'bold',
                  alignItems: 'center',
                  fontSize: '15px',
                }}
              >
                Tenant XPTO gerou o Acronis_Locked
              </Typography>
              <Button onClick={() => showInfoAlerts(null)}>X</Button>
            </Grid>
            <Grid>
              <TypographyDetails>Detalhes</TypographyDetails>
            </Grid>
            <Grid>
              <Grid
                key={selectedAlert.id}
                container
                sx={{ padding: '10px', gap: '30px' }}
              >
                <GridMenu>
                  <Typography variant="body1">Descrição:</Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {selectedAlert.description}
                  </Typography>
                </GridMenu>
                <GridMenu>
                  <Typography variant="body1">Count:</Typography>
                  <Typography variant="body1">{selectedAlert.count}</Typography>
                </GridMenu>
                <GridMenu>
                  <Typography variant="body1">Hash:</Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {selectedAlert.hash}
                  </Typography>
                </GridMenu>
                <GridMenu>
                  <Typography variant="body1">TenantId:</Typography>
                  <Typography variant="body1">{selectedAlert.tenantId}</Typography>
                </GridMenu>
                <GridMenu>
                  <Typography variant="body1">Última ocorrência:</Typography>
                  <Typography variant="body1">{selectedAlert.lastOccurrence}</Typography>
                </GridMenu>
              </Grid>
            </Grid>
            <Grid>
              <Grid>
                <TypographyDetails>Descrição Detalhada</TypographyDetails>
              </Grid>
              <Typography variant="body1" sx={{ padding: 2 }}>
                {selectedAlert.description}
              </Typography>
            </Grid>
            <Button component={Link} to={`/dashboard/alerts/${selectedAlert.id}`}>
              Ver mais
            </Button>
          </Paper>
        )}
      </Grid>
    </>
  );
};

export default Alertas;
