import React, { useMemo } from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import App from './containers/app';
import GlobalStyle from './globalStyle';
import configureStore from './redux/create';
import { BrowserRouter } from 'react-router-dom';
import useAuthToken from './hooks/useAuthToken';

const { store } = configureStore();

const EntryRoute = () => {
  const [authToken] = useAuthToken();
  const client = useMemo(
    () => new ApolloClient({
      link: createUploadLink({
        uri: process.env.REACT_APP_GQL_API,
        headers: { authorization: authToken ? `Bearer ${authToken}` : '' }
      }),
      cache: new InMemoryCache({
        addTypename: false
      })
    }),
    [authToken]
  );

  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <CssBaseline />
        <GlobalStyle />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
  );
};


ReactDOM.render(
  <EntryRoute />,
  document.getElementById('root')
);
