import React from 'react';
import Box from '@mui/material/Box';
import { Outlet, useNavigate } from 'react-router-dom';
import ActivityMenu from '../../components/activityMenu';
import useAuthToken from '../../hooks/useAuthToken';
import { useSelector } from 'react-redux';
import { selectDrawerWidth } from '../../redux/selectors/app.selector';
import styled from 'styled-components';


interface ExtraProps {
  drawerWidth: number;
}

export const ContentContainer = styled(Box)<ExtraProps>`
  width: 100%;
  flex-grow: 1;
  min-height: 100vh;
  height: 100%;
  padding: 3em;
  margin-top: 3em;
`;


const Dashboard = () => {
  const drawerWidth = useSelector(selectDrawerWidth);
  const [, , , isTokenValid] = useAuthToken();

  const navigate = useNavigate();
  if (!isTokenValid) {
    navigate('/login');
  }

  return (
    <Box>
      <ActivityMenu />
      <ContentContainer drawerWidth={drawerWidth}>
        <Outlet />
      </ContentContainer>
    </Box>
  );
};

export default Dashboard;
