import React, { useEffect } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import userSchema from './schema';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { userRolesMap } from '../../../utils/forms';
import { GET_ALL_PARTNERS } from '../../../gql/partners';
import {
  CREATE_USER,
  GET_ALL_USERS,
  GET_USER_BY_ID,
  RESET_PASSWORD,
  UPDATE_USER,
} from '../../../gql/users';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import useSnackbar from '../../../hooks/useSnackbar';
import { getTenantId } from '../../../services/tenancy';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyIcon from '@mui/icons-material/Key';
import InputMask from 'react-input-mask';

interface UserFormProps {
  uid?: string;
}

const UserForm: React.FC<UserFormProps> = ({ uid }) => {
  let { id } = useParams();
  id = uid ?? id;
  const isCreate = id === 'create';
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [mutateUser] = useMutation(isCreate ? CREATE_USER : UPDATE_USER, {
    refetchQueries: [{ query: GET_ALL_USERS }],
  });
  const { data: partnerData } = useQuery(GET_ALL_PARTNERS);
  const [getCurrentUser, { data, error }] = useLazyQuery(GET_USER_BY_ID, {
    fetchPolicy: 'no-cache',
  });
  const [resetPassword] = useMutation(RESET_PASSWORD, { fetchPolicy: 'no-cache' });
  const [hasResetedPassword, setHasResetedPassword] = React.useState(false);
  const [displayEditModal, setDisplayEditModal] = React.useState(false);

  const [, setCurrentEntity] = React.useState<Record<any, any> | undefined>(undefined);

  const handleCloseModal = () => {
    setCurrentEntity(undefined);
    setDisplayEditModal(false);
  };
  const handleOpenModal = () => {
    setDisplayEditModal(true);
  };

  const navigateBack = () => navigate('/dashboard/users');

  const handleResetPassword = () => {
    resetPassword({ variables: { input: { email: data.getUserById?.email } } });
    setHasResetedPassword(true);
  };

  const handleSubmit = (values: Record<any, any>) => {
    snackbar.setGlobalLoading(true);
    snackbar.sendAlert({ level: 'info', message: 'Salvando Dados...' });

    const input: Record<any, any> = { tenantId: getTenantId() };
    Object.keys(userSchema.fields).map((key) => {
      if (Object.hasOwn(values, key)) input[key] = values[key];
      return null;
    });

    mutateUser({ variables: { input } })
      .then(() => {
        snackbar.sendAlert({ level: 'success', message: 'Dados salvos com sucesso ' });
        navigateBack();
      })
      .catch((er) => {
        snackbar.sendAlert({
          level: 'error',
          message: `Erro Salvando dados: ${JSON.stringify(er)}`,
        });
        console.error(er);
      })
      .finally(() => {
        snackbar.setGlobalLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: data?.getUserById
      ? {
          ...data.getUserById,
          partnerId: data.getUserById?.partner?.id,
        }
      : {
          role: 0,
        },
    validationSchema: userSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!isCreate) getCurrentUser({ variables: { id } });
  }, [isCreate]);

  if (error) console.error(error);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4} justifyContent="center">
        <Dialog
          open={displayEditModal}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Editar Usuário</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Você tem certeza que gostaria de salvar essa edição?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancelar</Button>
            <Button type="submit" size="large" onClick={() => formik.handleSubmit()}>
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={4}>
          <Grid xs={12} item mt={5}>
            <Box display="flex" justifyContent="center" marginBottom={-6}>
              <Avatar
                style={{
                  width: 100,
                  height: 100,
                  cursor: 'pointer',
                }}
              >
                {formik.values.firstName || formik.values.lastName
                  ? `${formik.values.firstName ? formik.values.firstName[0] : ''} ${
                      formik.values.lastName ? formik.values.lastName[0] : ''
                    }`
                  : ''}
              </Avatar>
            </Box>
          </Grid>
          <Grid xs={12} item />
        </Grid>
        <Grid container item spacing={2} xs={12}>
          <Grid xs={6} item>
            <TextField
              fullWidth
              margin="normal"
              id="firstName"
              label="Nome"
              name="firstName"
              value={formik.values.firstName ?? ''}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
              variant="standard"
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              fullWidth
              margin="normal"
              id="lastName"
              label="Sobrenome"
              name="lastName"
              value={formik.values.lastName ?? ''}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} marginTop={2}>
            <FormControl fullWidth variant="standard">
              {/* @ts-ignore */}
              <InputMask
                mask="999.999.999.99"
                value={formik.values.cpf ?? ''}
                disabled={false}
                onChange={({ target }) => {
                  formik.setFieldValue('cpf', String(target.value.replace(/[^\d]/g, '')));
                }}
              >
                <TextField
                  fullWidth
                  autoFocus
                  placeholder="00.000.000/0000-00"
                  error={Boolean(formik.errors.cpf)}
                  name="cpf"
                  id="cpf-mask"
                  variant="standard"
                  label="cpf"
                  helperText={formik.errors.cpf}
                />
              </InputMask>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={formik.values.email ?? ''}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              variant="standard"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="partnerId" sx={{ marginLeft: -1.8 }}>
                Parceiro
              </InputLabel>
              <Select
                required
                variant="standard"
                labelId="partnerId"
                id="partnerId"
                value={formik.values.partnerId ?? ''}
                label="partnerId"
                name="partnerId"
                onChange={formik.handleChange}
                error={formik.touched.partnerId && Boolean(formik.errors.partnerId)}
              >
                {partnerData?.getAllPartners?.map((item: Record<any, any>) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.tradingName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.partnerId}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="Grupo">Grupo</InputLabel>
              <Select
                required
                labelId="role"
                id="role"
                value={formik.values.role ?? 0}
                label="Role"
                name="role"
                variant="standard"
                onChange={formik.handleChange}
                error={formik.touched.role && Boolean(formik.errors.role)}
              >
                {userRolesMap.map((item, index) => (
                  <MenuItem key={item} value={index}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {!isCreate ? (
            <Grid
              item
              xs={12}
              display="flex"
              marginBottom={5}
              justifyContent="space-between"
            >
              <Grid justifyContent="flex-start">
                <Button
                  variant="outlined"
                  size="small"
                  type="submit"
                  disabled={hasResetedPassword}
                  onClick={handleResetPassword}
                >
                  <KeyIcon
                    fontSize="small"
                    style={{ transform: 'rotate(270deg)', marginRight: 6 }}
                  />{' '}
                  Resetar senha
                </Button>
              </Grid>
              <Grid display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  type="submit"
                  disabled={hasResetedPassword}
                  onClick={handleResetPassword}
                >
                  <DeleteIcon fontSize="small" style={{ marginRight: 6 }} /> Excluir
                  Usuário
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <TextField
                required
                margin="normal"
                fullWidth
                type="password"
                id="password"
                label="Senha"
                name="password"
                value={formik.values.password ?? ''}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                variant="standard"
              />
            </Grid>
          )}
          <Grid
            item
            container
            spacing={2}
            xs={12}
            alignItems="baseline"
            justifyContent="flex-end"
          >
            <Grid item>
              <Button size="large" onClick={navigateBack}>
                Cancelar
              </Button>
            </Grid>
            <Grid>
              {!isCreate ? (
                <Grid item xs={6} justifyContent="flex-end">
                  <Button
                    onClick={handleOpenModal}
                    variant="contained"
                    size="large"
                    type="button"
                  >
                    Salvar
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <Button variant="contained" size="large" type="submit">
                    Salvar
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserForm;
