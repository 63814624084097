import * as yup from 'yup';
import { requiredField } from '../../../utils/forms';

export default yup.object({
  id: yup
    .string()
    .label('id'),
  description: yup
    .string()
    .required(requiredField('Descrição do imposto')),
  type: yup
    .string()
    .required(requiredField('Tipo do imposto')),
  value: yup
    .string()
    .required(requiredField('Valor Sugerido'))
});

