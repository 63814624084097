import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';

const companies = [
  { name: 'Empresa 1', contracts: Math.floor(Math.random() * 100) },
  { name: 'Empresa 2', contracts: Math.floor(Math.random() * 100) },
  { name: 'Empresa 3', contracts: Math.floor(Math.random() * 100) },
  { name: 'Empresa 4', contracts: Math.floor(Math.random() * 100) },
  { name: 'Empresa 5', contracts: Math.floor(Math.random() * 100) },
];

const TableWithoutRows = () => {
  const [filter, setFilter] = useState('');

  const handleFilterChange = (event: any) => {
    setFilter(event.target.value);
  };

  const filteredCompanies = companies.filter((company) =>
    company.name.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <Paper>
      <Grid item container display="flex" justifyContent="flex-end">
        <TextField
          label="Filtrar"
          variant="standard"
          value={filter}
          onChange={handleFilterChange}
          style={{ marginBottom: 16 }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Empresa</TableCell>
              <TableCell align="right">Nº de contratos</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCompanies.map((company) => (
              <TableRow key={company.name}>
                <TableCell component="th" scope="row">
                  {company.name}
                </TableCell>
                <TableCell
                  sx={{ display: 'flex', align: 'right', justifyContent: 'flex-end' }}
                >
                  <NoteAltIcon sx={{ marginRight: 1 }} />
                  {`${company.contracts} contratos`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableWithoutRows;
