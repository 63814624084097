import React from 'react';
import Paper from '@mui/material/Paper';
import { useMutation, useQuery } from '@apollo/client';
import GenericTable from '../../components/genericTable';
import { useNavigate } from 'react-router-dom';
import { DELETE_MANUFACTURER, GET_ALL_MANUFACTURERS } from '../../gql/manufacturers';
import Factory from '@mui/icons-material/Factory';
import ActionButton from '../../components/actionButton';

const columnsOptions = {
  id: {
    hide: true,
  },
  name: {
    headerName: 'Nome do Fabricante',
  },
};

const ManufacturersRoute = () => {
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_ALL_MANUFACTURERS);
  const [deleteManufacturer] = useMutation(DELETE_MANUFACTURER, {
    refetchQueries: [{ query: GET_ALL_MANUFACTURERS }],
  });

  const handleNewManufacturer = () => navigate('/dashboard/manufacturers/create');
  const handleEditManufacturer = ({ id = '' }: { id?: string }) => navigate(`/dashboard/manufacturers/${id}`);
  const handleDeleteManufacturer = ({ id = '' }) =>
    deleteManufacturer({ variables: { id } });

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <GenericTable
        data={data?.getAllManufacturers}
        loading={loading}
        columnOptions={columnsOptions}
        onEdit={handleEditManufacturer}
        onDelete={handleDeleteManufacturer}
      />
      <ActionButton
        title="Criar novo fabricante"
        typography={<Factory fontSize="medium" />}
        onClick={handleNewManufacturer}
      />
    </Paper>
  );
};

export default ManufacturersRoute;
