import { gql } from '@apollo/client';

export const GET_ALL_INVOICES = gql`
  query getAllInvoices {
    getAllInvoices {
      id
      saleId
      dueDates
      endDate
      partnerId
      startDate
      status
      tenantId
      totalValue
      createdAt
      immutableData
      contract {
        id
        sellerId
      }
    }
  }
`;

export const GET_INVOICE_BY_ID = gql`
  query getInvoiceById($id: String!) {
    getInvoiceById(id: $id) {
      id
      immutableData
      data {
        credits {
          active
          creditType
          value
          valueModel
          remainingMonths
          issuedDays
          description
          currency
          currencyType
          currencyValue
        }
        regions {
          namespace
          wallet
          items {
            item
            qtd
            sku
            total
            unitPrice
          }
        }
        client {
          companyName
          cnpj
          tradingName
        }
        contact {
          telephone
          contactEmail
          contactName
          contactNumber
        }
        companyAddress {
          city
          state
          street
          number
          complement
          zipcode
          district
        }
        taxes {
          name
          description
          value
          type
        }
        dollarRate
        dollarType
        observations {
          id
          message
          type
        }
      }
      dueDates
      endDate
      partnerId
      saleId
      nfeId
      statusNfe
      startDate
      totalValue
      status
      tenantId
      nfeNumber
      nfeDate
      contract {
        product_tier
        sellerId
        seller {
          firstName
          lastName
        }
      }
      createdAt
    }
  }
`;
export const DOWNLOAD_INVOICE = gql`
  query downloadInvoice($id: String!) {
    downloadInvoice(id: $id)
  }
`;
export const DOWNLOAD_NFE = gql`
  query getNfePdf($id: String!) {
    getNfePdf(id: $id)
  }
`;
export const RUN_INVOICING = gql`
  mutation runInvoicing {
    runInvoicing {
      id
    }
  }
`;
/*
export const MANUAL_NFE_ISSUE = gql`
  mutation manualIssueNfe ($ids: [String!]!) {
    manualIssueNfe (ids: $ids)
  }
`;
*/
export const UPDATE_INVOICE = gql`
  mutation updateInvoice($input: UpdateInvoiceInput!) {
    updateInvoice(invoice: $input) {
      id
      data {
        regions {
          namespace
          items {
            item
            qtd
            sku
            total
            unitPrice
          }
        }
        client {
          companyName
          cnpj
          tradingName
        }
        contact {
          telephone
          contactEmail
          contactNumber
        }
        companyAddress {
          city
          state
          street
          number
          complement
          zipcode
          district
        }
        taxes {
          value
          type
        }
        credits {
          active
          creditType
          value
          valueModel
          remainingMonths
          issuedDays
          description
          currency
          currencyType
          currencyValue
        }
        dollarRate
        dollarType
        observations {
          id
          message
          type
        }
      }
      dueDates
      endDate
      partnerId
      saleId
      startDate
      status
      tenantId
    }
  }
`;
export const CREATE_INVOICE = gql`
  mutation createInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      id
      data {
        regions {
          namespace
          items {
            item
            qtd
            sku
            total
            unitPrice
          }
        }
        client {
          companyName
          cnpj
          tradingName
        }
        contact {
          telephone
          contactEmail
          contactNumber
        }
        companyAddress {
          city
          state
          street
          number
          complement
          zipcode
          district
        }
        taxes {
          value
          type
        }
      }
      dueDates
      endDate
      partnerId
      saleId
      startDate
      status
      tenantId
    }
  }
`;
export const DELETE_INVOICE = gql`
  mutation removeInvoice($id: String!) {
    removeInvoice(id: $id)
  }
`;
export const CREATE_DRAFT_INVOICE = gql`
  mutation createDraftInvoice($input: CreateInvoiceInput!) {
    createDraftInvoice(input: $input) {
      id
      contractId
      data {
        regions {
          namespace
          items {
            item
            qtd
            sku
            total
            unitPrice
          }
        }
        client {
          companyName
          cnpj
          tradingName
        }
        contact {
          telephone
          contactEmail
          contactNumber
        }
        companyAddress {
          city
          state
          street
          number
          complement
          zipcode
          district
        }
        taxes {
          value
          type
        }
        credits {
          active
          creditType
          value
          valueModel
          remainingMonths
          issuedDays
          description
          currency
          currencyType
          currencyValue
        }
        dollarRate
        dollarType
        observations {
          id
          message
          type
        }
      }
      dueDates
      endDate
      partnerId
      saleId
      startDate
      status
      tenantId
      totalValue
      pdf
    }
  }
`;
