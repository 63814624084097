import { useQuery } from '@apollo/client';
import { GET_USER_ROLE } from '../gql/users';
import { Role } from '../utils/roles'

const AdminRoles = [Role.ADMIN, Role.SUPER_ADMIN];

const AccountingAdmin = [...AdminRoles, Role.ACCOUNTING_ADMIN];
const AccountingUser = [...AccountingAdmin, Role.ACCOUNTING_USER];

const SalesAdmin = [...AdminRoles, Role.SALES_ADMIN];
const SalesUser = [...SalesAdmin, Role.SALES_USER];

const MarketingAdmin = [...AdminRoles, Role.MARKETING_ADMIN];
const MarketingUser = [...MarketingAdmin, Role.MARKETING_USER];


const PartnerAdmin = [...AdminRoles, Role.PARTNER_ADMIN];
const PartnerUser = [...PartnerAdmin, Role.PARTNER_USER];

const usePermissions = () => {
  const { data = {}, loading } = useQuery(GET_USER_ROLE, {
    fetchPolicy: 'cache-first',
  });

  const { me } = data || {};
  const { role, id } = me || {};

  const isOwner = (entityOwnerId: string | number) => entityOwnerId === id;

  return {
    loading,
    isSuperAdmin: role === Role.SUPER_ADMIN,
    isAdmin: AdminRoles.includes(role),
    isAccountingAdmin: AccountingAdmin.includes(role),
    isAccountingUser: AccountingUser.includes(role),
    isSalesAdmin: SalesAdmin.includes(role),
    isSalesUser: SalesUser.includes(role),
    isMarketingAdmin: MarketingAdmin.includes(role),
    isMarketingUser: MarketingUser.includes(role),
    isPartnerAdmin: MarketingAdmin.includes(role),
    isPartnerUser: PartnerUser.includes(role),
    isUnauthorized: role === Role.UNAUTHORIZED,
    isOwner
  }
};

export default usePermissions;
