import React from 'react';
import { CircularLoader, LoaderBox, LoadingWrapper } from './styles';

const LoadingScreen = () => (
  <LoadingWrapper>
    <LoaderBox sx={{ display: 'flex' }}>
      <CircularLoader size={100} />
    </LoaderBox>
  </LoadingWrapper>
);

export default LoadingScreen;
