import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { formatBrl } from '../../utils/numbers';

interface GenericTableInvoicesProps {
  regions: Record<string, any>[];
}

const GenericTableInvoices = ({ regions = [] }: GenericTableInvoicesProps) => {
  const totalPrice = (data: Record<string, any>) =>
    data.reduce((total: number, item: Record<string, any>) => total + item.total, 0);

  const createRows = (data: Record<string, any>) =>
    data.map((item: Record<string, any>, index: number) => (
      <TableRow key={index}>
        <TableCell>{item.item}</TableCell>
        <TableCell>{item.sku}</TableCell>
        <TableCell align="center">{item.qtd}</TableCell>
        <TableCell align="right">{formatBrl(item.unitPrice)}</TableCell>
        <TableCell align="right">{formatBrl(item.total)}</TableCell>
      </TableRow>
    ));

  return (
    <Grid item xs={12} sm={6} md={3}>
      {regions.map((region: Record<string, any>) => (
        <Grid item key={region.wallet} sx={{ marginBottom: 5 }} xs={12}>
          <Typography
            variant="h5"
            sx={{ marginTop: 2, marginLeft: 1, marginBottom: 1, fontSize: { sm: 15, md: 25 } }}
          >
            <b>{region.wallet}</b>
          </Typography>
          <TableContainer
            component={Paper}
            sx={{
              height: { sm: '30%', md: '100%' },
              [`& .MuiTableCell-root`]: {
                [`@media (max-width: 960px)`]: {
                  padding: '4px',
                  fontSize: 10,
                },
              },
            }}
          >
            <Table aria-label="caption table">
              <caption
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  textAlign: 'end',
                  marginRight: 4,
                  width: '100%',
                }}
              >
                Total: {formatBrl(totalPrice(region.items))}
              </caption>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Item</b>
                  </TableCell>
                  <TableCell>
                    <b>Cod.(SKU)</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Quantidade</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>Preço Unitário</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>Total(R$)</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{createRows(region.items)}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ))}
    </Grid>
  );
};

export default GenericTableInvoices;
