import { gql } from '@apollo/client';

export const GET_ALL_WALLETS_IDS = gql`
  query getAllWallets {
      getAllWallets {
        partner {
          id
        }
      }
  }`;
export const GET_ALL_WALLETS = gql`
  query getAllWallets {
    getAllWallets {
      id,
      partner {
        tradingName
      }
      data{
        value,
        namespace,
        key,
        description
      },
      tenantId,
    }
  }`;

export const GET_WALLET_BY_ID = gql`
  query getWalletById($id: String!){
    getWalletById(id: $id) {
      id,
      data {
        manufacturerId
        value
        description
        key
        namespace
      },
      tenantId,
      partner {
        tradingName
        id
      }
      
    }
    
  }`;


export const CREATE_WALLET = gql`
  mutation createWallet ($input: CreateWalletInput!) {
    createWallet (input: $input) {
      id,
      data{
        value
      },
      tenantId,
    }
  }`;


export const UPDATE_WALLET = gql`
  mutation updateWallet ($input: UpdateWalletInput!) {
    updateWallet (input: $input) {
      id
    }
  }
`;

export const DELETE_WALLET = gql`
  mutation deleteWallet ($id: String!) {
    deleteWallet(id: $id)
  }
`;

