import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
    background: #F5F5F5;
  }

  .expand-menu {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 2em;
    padding: 0 0.5em;
    text-align: center;
    cursor: pointer;
    svg {
      margin: 0 auto;
      text-align: center;
      font-size: 1em;
    }
  }
`;

export default GlobalStyle;
