import * as yup from 'yup';
import { requiredField, minField } from '../../../utils/forms';

const baseProfileSchema = {
  firstName: yup
    .string()
    .label('Nome')
    .required(requiredField('Nome')),
  lastName: yup
    .string()
    .label('Sobrenome')
    .required(requiredField('Sobrenome')),
  email: yup
    .string()
    .email()
    .required(requiredField('Email'))
};

const profileSchema = yup.object(baseProfileSchema);

const updatePasswordSchema = yup.object({
  ...baseProfileSchema,
  password: yup
    .string()
    .label('Senha')
    .min(8, minField(8, 'Senha'))
    .required(requiredField('Senha')),
  confirmPassword: yup
    .string()
    .label('Confirmar senha')
    .min(8, minField(8, 'Confirmar senha'))
    .oneOf([yup.ref('password')], 'Senhas devem corresponder')
    .required(requiredField('Confirmar senha')),
});

export { profileSchema, updatePasswordSchema };
