import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';


const useLocationName = () => {
  const { pathname } = useLocation();
  return useMemo(() => pathname.replace(/(\/)dashboard(\/|)/, ''), [pathname]);
};

export default useLocationName;
