import React from 'react';
import Header from '../../components/header';
import Paper from '@mui/material/Paper';
import GenericTable from '../../components/genericTable';
import ActionButton from '../../components/actionButton';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { useNavigate } from 'react-router-dom';

const columnsOptions = {
  id: {
    hide: true,
  },
  seller: {
    headerName: 'Vendedor',
  },
  CNPJ: {
    headerName: 'CNPJ',
  },
  corporateName: {
    headerName: 'Razão Social',
  },
  fantasyName: {
    headerName: 'Nome Fantasia',
  },
  timeTrial: {
    headerName: 'Tempo de Trial',
  },
  trialStatus: {
    headerName: 'Status de Trial',
  },
};

const rows = [
  {
    id: 1,
    seller: 'Vinicius',
    CNPJ: '12345678901',
    corporateName: 'Vinicius',
    fantasyName: 'Vinicius',
    timeTrial: '2020-01-01',
    trialStatus: 'Aberto',
  },
  {
    id: 2,
    seller: 'João Keuwe',
    CNPJ: '12345678901',
    corporateName: 'João Keuwe',
    fantasyName: 'João Keuwe',
    timeTrial: '2020-01-01',
    trialStatus: 'Aberto',
  },
];

const Assessments = () => {
  const navigate = useNavigate();
  const newAssessment = () => navigate('create');
  return (
    <>
      <Header title="Avaliações" />
      <Paper
        sx={{
          width: '100%',
          mb: 2,
        }}
      >
        <GenericTable columnOptions={columnsOptions} data={rows} />
      </Paper>
      <ActionButton
        title="Criar Avaliação"
        typography={<AssignmentTurnedInIcon />}
        onClick={newAssessment}
      />
    </>
  );
};

export default Assessments;
