import { gql } from '@apollo/client';

export const GET_ALL_TAXES = gql`
  query getAllTaxes {
    getAllTaxes {
      id,
      description,
      value,
      type
    }
  }`;

export const GET_TAX_BY_ID = gql`
  query getTaxById($id: String!){
    getTaxById(id: $id) {
      id,
      description,
      value,
      type,
      tenantId
    }
  }`;


export const CREATE_TAX = gql`
  mutation createTax ($input: CreateTaxInput!) {
    createTax (createTaxInput: $input) {
      id
      description,
      value,
      type
    }
  }`;


export const UPDATE_TAX = gql`
  mutation updateTax ($input: UpdateTaxInput!) {
    updateTax (updateTaxInput: $input) {
      id
    }
  }
`;

export const DELETE_TAX = gql`
  mutation deleteTax ($id: String!) {
    deleteTax (id: $id)
  }
`;

