import { AlertColor } from '@mui/material';

const SET_DRAWER_WIDTH = 'partner-portal/app/SET_DRAWER_WIDTH';
const TOGGLE_DRAWER = 'partner-portal/app/TOGGLE_DRAWER';


const SEND_ALERT = 'partner-portal/app/SEND_ALERT';
const DISMISS_ALERT = 'partner-portal/app/DISMISS_ALERT';

const SET_GLOBAL_LOADING = 'partner-portal/app/SET_GLOBAL_LOADING';


interface AlertI {
  message: string | undefined;
  level: AlertColor | undefined;
}

export const initialState = {
  // here we store general app states like:
  // i18n : current language
  // theme: current theme
  isDrawerOpen: false,
  displayAlert: false,
  currentAlert: {},
  globalLoading: false
};


export default function reducer(state = { ...initialState }, action: { type: string | string[], payload: object }) {
  switch (action.type) {
    case SET_DRAWER_WIDTH: {
      return {
        ...state,
        isDrawerOpen: action.payload
      };
    }
    case TOGGLE_DRAWER: {
      return {
        ...state,
        isDrawerOpen: !state.isDrawerOpen
      };
    }
    case SEND_ALERT: {
      return {
        ...state,
        displayAlert: true,
        currentAlert: action.payload
      };
    }
    case DISMISS_ALERT: {
      return {
        ...state,
        displayAlert: false
      };
    }
    case SET_GLOBAL_LOADING: {
      return {
        ...state,
        globalLoading: action.payload
      };
    }
    default:
      return state;
  }
}


export function setOpenDrawer(payload: boolean) {
  return {
    type: SET_DRAWER_WIDTH,
    payload
  };
}

export function toggleDrawer() {
  return {
    type: TOGGLE_DRAWER
  };
}


export function sendAlert(payload: AlertI) {
  return {
    type: SEND_ALERT,
    payload
  };
}

export function dismissAlert() {
  return {
    type: DISMISS_ALERT
  };
}

export function setLoading(payload: boolean) {
  return {
    type: SET_GLOBAL_LOADING,
    payload
  };
}
