import React from 'react';
import Header from '../../../components/header';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const CreateAssessment = () => (
  <form>
    <Header title="Criar avaliação" />
    <Paper
      sx={{
        m: 'auto',
        p: 2,
        Color: '#000000',
      }}
    >
      <Grid item xs={12} container spacing={3}>
        <Grid xs={6} item>
          <TextField
            fullWidth
            margin="normal"
            id="cnpj"
            label="CNPJ"
            name="cnpj"
            variant="standard"
          />
        </Grid>
        <Grid xs={6} item>
          <TextField
            fullWidth
            margin="normal"
            id="companyId"
            label="PartnerID da Empresa"
            name="companyId"
            variant="standard"
          />
        </Grid>
        <Grid xs={6} item>
          <TextField
            fullWidth
            margin="normal"
            id="razaoSocial"
            label="Razão Social"
            name="razaoSocial"
            variant="standard"
          />
        </Grid>
        <Grid xs={6} item>
          <TextField
            fullWidth
            margin="normal"
            id="nomeFantasia"
            label="Nome Fantasia"
            name="nomeFantasia"
            variant="standard"
          />
        </Grid>
        <Grid xs={6} item>
          <TextField
            fullWidth
            margin="normal"
            id="seller"
            label="Vendedor"
            name="seller"
            variant="standard"
          />
        </Grid>
        <Grid xs={6} item>
          <TextField
            fullWidth
            margin="normal"
            id="userName"
            label="Nome de Usuário de Acesso"
            name="userName "
            variant="standard"
          />
        </Grid>
        <Grid xs={6} item>
          <TextField
            fullWidth
            margin="normal"
            id="userEmail"
            label="E-mail de Usuário de Acesso"
            name="userEmail "
            variant="standard"
          />
        </Grid>
        <Grid xs={6} item>
          <TextField
            fullWidth
            margin="normal"
            id="timeTrial"
            label="Período de Trial"
            name="timeTrial"
            variant="standard"
          />
        </Grid>
      </Grid>
      <Grid item xs={6} mt={4} mb={2} display="flex" justifyContent="flex-end">
        <Button size="large" type="submit">
          Cancelar
        </Button>
        <Button variant="contained" size="large" type="submit">
          Criar
        </Button>
      </Grid>
    </Paper>
  </form>
);

export default CreateAssessment;
