import styled from 'styled-components';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

export const LoginContainer = styled(Container)`
  background-color: #e6e6e6;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  max-width: none!important;
`;

export const LoginBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoginPaper = styled(Paper)`
  margin-top: 6em;
  padding: 2em 3em;
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 550px;
`;


export const LoginAvatar = styled(Avatar)`
  margin: 3em;
  border: 1px solid #ccc;
  padding: 2em;
  width: 130px;
  height: 130px;
`;
