import { gql } from '@apollo/client';

export const CREATE_POST = gql`
  mutation createPost ($input: CreatePostInput!) {
    createPost (input: $input) {
      attached
      authorId
      contentType
      cover
      createdAt
      desc
      id
      isPublished
      tags
      tenantId
      title
      updatedAt
    }
  }`;


export const UPDATE_POST = gql`
  mutation updatePost ($input: UpdatePostInput!) {
    updatePost (input: $input) {
      attached
      authorId
      contentType
      cover
      createdAt
      desc
      id
      isPublished
      tags
      tenantId
      title
      updatedAt
    }
  }`;


export const GET_ALL_POSTS = gql`
  query getAllPosts {
    getAllPosts {
      id
      title
      desc
      contentType
      tags
      authorId
      isPublished
    }
  }`;

export const DELETE_POST = gql`
  mutation removePost ($id: Int!) {
    removePost (id: $id) {
        id
    }
  }
`;
