import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import validationSchema from './schema';
import Grid from '@mui/material/Grid';
import {
  CREATE_INVOICE,
  DOWNLOAD_INVOICE,
  GET_ALL_INVOICES,
  GET_INVOICE_BY_ID,
  UPDATE_INVOICE,
} from '../../../gql/invoices';
import Button from '@mui/material/Button';
import useSnackbar from '../../../hooks/useSnackbar';
import { getTenantId } from '../../../services/tenancy';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { formatToTimeZone } from 'date-fns-timezone';
import usePermissions from '../../../hooks/usePermissions';

const timezone = { timeZone: 'UTC' };
const toBRL = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
interface InvoiceFormProps {
  uid?: string;
}
// @ts-ignore
const InvoiceForm: React.FC<InvoiceFormProps> = ({ uid }) => {
  const { state } = useLocation();
  let { id } = useParams();
  id = uid ?? id;
  const isCreate = id === 'create';
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  // const auth = useAuthToken();
  // const baseUrl = process.env.REACT_APP_REST_API;
  const [mutateInvoice] = useMutation(isCreate ? CREATE_INVOICE : UPDATE_INVOICE, {
    refetchQueries: [{ query: GET_ALL_INVOICES }],
  });
  const [getCurrentInvoice, { data }] = useLazyQuery(GET_INVOICE_BY_ID, {
    fetchPolicy: 'no-cache',
  });
  const inv = useQuery(DOWNLOAD_INVOICE, { fetchPolicy: 'no-cache', variables: { id } });
  const { isAdmin, isOwner } = usePermissions();

  
  

  const navigateBack = () => navigate('/dashboard/invoices');
  /* const handleSwitch = (index: number) => {
    const { data } = formik.values;
    console.log(index);
    switch (data.observations?.[index].type) {
      case 'INTERNAL':
        data.observations[index].type = 'DEFAULT';
        break;
      default:
        data.observations[index].type = 'INTERNAL';
        break;
    }
    formik.setFieldValue('data', data);
  };
  const getStatus = () => {
    console.log(formik.values.statusNfe);
    switch (formik.values.statusNfe) {
      case '0':
        return 'Processando';
      case '1':
        return 'Emitida';
      case '2':
        return 'Cancelada';
      default:
        return 'Desconhecido';
    }
  };
  /* const handleObservation = () => {
    const { data } = formik.values;
    console.log(data);
    const newObs = {
      message: '',
      type: 'DEFAULT',
    };
    data.observations.push(newObs);
    formik.setFieldValue('data', data);
    console.log(data, formik.values.immutableData.observations);
  }; */
  // const downloadNFE = () => {
  //   console.log(auth);
  //   fetch(`${baseUrl}invoices/${id}`, {
  //     headers: {
  //       Authorization: `Bearer ${auth[0]}`
  //     }
  //   }).then(response => response.blob())
  //     .then(blobby => {
  //       const link = `${URL.createObjectURL(blobby)}`;
  //       const downloadLink = document.createElement('a');
  //       const fileName = `Nfe_${formik.values.nfeId}.pdf`;
  //       downloadLink.href = link;
  //       downloadLink.download = fileName;
  //       downloadLink.click();
  //     });
  // };
  const downloadINV = async () => {
    // @ts-ignore
    // eslint-disable-next-line valid-typeof
    const str = state !== null ? state.pdf : inv.data.downloadInvoice;
    const link = `data:application/pdf;base64,${str}`;
    const downloadLink = document.createElement('a');
    const fileName = `Pedido_de_venda_${formik.values.saleId}.pdf`;
    downloadLink.href = link;
    downloadLink.download = fileName;
    downloadLink.click();
  };
  const handleSubmit = (values: Record<any, any>) => {
    snackbar.setGlobalLoading(true);
    snackbar.sendAlert({ level: 'info', message: 'Salvando Dados...' });

    const input: Record<string, any> = { tenantId: getTenantId() };
    Object.keys(validationSchema.fields).map((key) => {
      if (Object.hasOwn(values, key)) input[key] = values[key];
      return null;
    });
    mutateInvoice({ variables: { input } })
      .then(() =>
        snackbar.sendAlert({ level: 'success', message: 'Dados salvos com sucesso ' })
      )
      .catch((er) => {
        snackbar.sendAlert({ level: 'error', message: 'Erro Salvando dados' });
        console.error(er);
      })
      .finally(() => {
        snackbar.setGlobalLoading(false);
        navigateBack();
      });
  };
  /* const removeDiscount = (resourceIndex: number) => () => {
    const { data } = formik.values;
    data.observations.splice(resourceIndex, 1);
    data.observations = [...data.observations];
    formik.setFieldValue('data', data);
  }; */
  const formik = useFormik({
    // eslint-disable-next-line no-nested-ternary
    initialValues: data?.getInvoiceById
      ? {
          ...data.getInvoiceById,
          invoiceId: data.getInvoiceById?.invoice?.id,
        }
      : typeof state === 'object'
      ? {
          ...state,
          // @ts-ignore
          invoiceId: state?.id,
        }
      : {},
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  /* const handleDateChange = (fieldName: string) => (newDate: Date | number | null) => {
    if (newDate) {
      formik.setFieldValue(fieldName, getUnixTime(newDate) * 1000);
    }
  }; */

  useEffect(() => {
    if (!isCreate) getCurrentInvoice({ variables: { id } });
  }, [isCreate]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid item container justifyContent="center">
        <Grid
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          item
          xs={3}
        >
          <Typography
            align="center"
            variant="h6"
          >{`Pedido de Venda Nº ${formik.values.saleId}`}</Typography>
          <Typography align="center" variant="subtitle1">
            {formatToTimeZone(
              new Date(Number(formik.values.startDate)),
              'DD/MM/YYYY',
              timezone
            )}
            à
            {formatToTimeZone(
              new Date(Number(formik.values.endDate)),
              'DD/MM/YYYY',
              timezone
            )}
          </Typography>
        </Grid>
        <Grid item xs={7} />
        <Grid
          item
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          xs={2}
        >
          <Typography align="center" variant="h6">{`NF-e ${
            !formik.values.nfeNumber ? '---' : formik.values.nfeNumber
          }`}</Typography>
        </Grid>
      </Grid>
      <Grid item container justifyContent="center" my={3} />
      <Grid item container>
        <Grid
          style={{
            borderStyle: 'solid',
            marginBottom: -1,
            borderWidth: '0px 0px 1px 0px',
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          item
          xs={5}
        >
          <Typography align="center" variant="h6">
            CLIENTE
          </Typography>
        </Grid>
        <Grid item xs={3} />
        <Grid
          item
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            marginBottom: -1,
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          xs={4}
        >
          <Typography align="center" variant="h6">
            NF-e
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            marginBottom: -1,
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          item
          xs={2}
        >
          <Typography align="center" variant="h6">
            Razão Social
          </Typography>
        </Grid>
        <Grid
          item
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            marginBottom: -1,
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          xs={3}
        >
          <Typography align="center">
            {formik.values.immutableData?.client?.companyName}
          </Typography>
        </Grid>
        <Grid item xs={3} />
        <Grid
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            marginBottom: -1,
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          item
          xs={2}
        >
          <Typography align="center" variant="h6">
            Pedido de Venda
          </Typography>
        </Grid>
        <Grid
          item
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            marginBottom: -1,
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          xs={2}
        >
          <Typography align="center">{formik.values.saleId}</Typography>
        </Grid>
      </Grid>
      <Grid item container justifyContent="center">
        <Grid
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            marginBottom: -1,
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          item
          xs={2}
        >
          <Typography align="center" variant="h6">
            Nome Fantasia
          </Typography>
        </Grid>
        <Grid
          item
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            marginBottom: -1,
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          xs={3}
        >
          <Typography align="center">
            {formik.values.immutableData?.client?.tradingName}
          </Typography>
        </Grid>
        <Grid item xs={3} />
        <Grid
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            marginBottom: -1,
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          item
          xs={2}
        >
          <Typography align="center" variant="h6">
            Emissao do PV
          </Typography>
        </Grid>
        <Grid
          item
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            marginBottom: -1,
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          xs={2}
        >
          <Typography align="center">
            {new Date(formik.values.createdAt ?? formik.values.endDate).toLocaleString(
              'PT-BR',
              { day: '2-digit', month: '2-digit', year: '2-digit' }
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container justifyContent="center">
        <Grid
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            marginBottom: -1,
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          item
          xs={2}
        >
          <Typography align="center" variant="h6">
            Endereço
          </Typography>
        </Grid>
        <Grid
          item
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            marginBottom: -1,
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          xs={3}
        >
          <Typography align="center">{`${
            formik.values.immutableData?.companyAddress?.street
          }, ${formik.values.immutableData?.companyAddress?.number}${
            formik.values.immutableData?.companyAddress?.complement
              ? `, ${formik.values.immutableData?.companyAddress?.complement}`
              : ''
          } - ${
            formik.values.immutableData?.companyAddress?.district
          } ${formik.values.immutableData?.companyAddress?.zipcode
            .replace(/\D/g, '')
            .match(/(\d{5})(\d{3})/)
            .slice(1, 3)
            .join('-')} - ${formik.values.immutableData?.companyAddress?.city}, ${
            formik.values.immutableData?.companyAddress?.state
          }`}</Typography>
        </Grid>
        <Grid item xs={3} />
        <Grid
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            marginBottom: -1,
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          item
          xs={2}
        >
          <Typography align="center" variant="h6">
            Nota Fiscal Eletron.
          </Typography>
        </Grid>
        <Grid
          item
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            marginBottom: -1,
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          xs={2}
        >
          <Typography align="center">
            {!formik.values.nfeNumber ? '---' : formik.values.nfeNumber}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container justifyContent="center">
        <Grid
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            marginBottom: -1,
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          item
          xs={2}
        >
          <Typography align="center" variant="h6">
            Contato
          </Typography>
        </Grid>
        <Grid
          item
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            marginBottom: -1,
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          xs={3}
        >
          <Typography align="center">
            {formik.values.immutableData?.contact?.contactName}
          </Typography>
        </Grid>
        <Grid item xs={3} />
        <Grid
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            marginBottom: -1,
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          item
          xs={2}
        >
          <Typography align="center" variant="h6">
            Emissão da NF-e
          </Typography>
        </Grid>
        <Grid
          item
          style={{
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            marginBottom: -1,
            borderColor: 'rgba(50,50,50,0.3)',
          }}
          xs={2}
        >
          <Typography align="center">
            {!formik.values.nfeDate
              ? '---'
              : new Date(formik.values.nfeDate).toLocaleString('PT-BR', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                })}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item container justifyContent="center" spacing={2}>
          {(isOwner(data?.getContractById?.sellerId) || isAdmin) && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h4" textAlign="center" marginY={5}>
                    Itens de Cobrança
                  </Typography>
                </Grid>
                <>
                  <Grid item xs={12}>
                    {(formik.values?.immutableData?.regions ?? []).map((region: any) => (
                      <Grid item xs={12} key={region.namespace} container spacing={2}>
                        <Grid item xs={12}>
                          <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    Região: {region.namespace} | Tier:
                                    {formik.values.contract?.product_tier} | Parceiro:
                                    {region.wallet}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>SKU</TableCell>
                                  <TableCell>Item</TableCell>
                                  <TableCell>Qtd</TableCell>
                                  <TableCell>Valor Unitario</TableCell>
                                  <TableCell>Total</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(region.items ?? []).map((row: any, idx: number) => (
                                  <TableRow
                                    key={row.sku + region.namespace + idx}
                                    sx={{
                                      '&:last-child td, &:last-child th': { border: 0 },
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {row.sku}
                                    </TableCell>
                                    <TableCell>{row.item}</TableCell>
                                    <TableCell>{row.qtd}</TableCell>
                                    <TableCell>{toBRL.format(row.unitPrice)}</TableCell>
                                    <TableCell>{toBRL.format(row.total)}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <h5>
                      {formik.values?.immutableData?.dollarType}
                      |Dollar|
                      {formik.values?.immutableData?.dollarRate}
                    </h5>
                    <h3>
                      SubTotal:
                      {toBRL.format(
                        formik.values?.immutableData?.regions.reduce(
                          (pv: number, cv: any) =>
                            pv +
                            cv.items.reduce(
                              (pv: number, cv: any) => pv + Number(cv.total),
                              0
                            ),
                          0
                        )
                      )}
                    </h3>
                    <h3>
                      Descontos:
                      {toBRL.format(
                        formik.values?.immutableData?.credits.reduce(
                          (pv: number, cv: any) => pv + Number(cv.value),
                          0
                        )
                      )}
                    </h3>
                    <h3>Total: {toBRL.format(formik.values.totalValue)}</h3>
                  </Grid>
                </>
              </>
            )}
        </Grid>
        <Grid item container xs={12} spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4">Impostos</Typography>
          </Grid>

          {formik.values.immutableData?.taxes.map((tax: any, index: number) => (
            <Grid item xs={12} key={`tax_${index}`} container spacing={2}>
              <Grid
                style={{
                  borderStyle: 'solid',
                  borderWidth: '0px 0px 1px 0px',
                  borderColor: 'rgba(50,50,50,0.3)',
                }}
                item
                xs={1}
              >
                <Typography align="center" variant="h6">
                  Nome:
                </Typography>
              </Grid>
              <Grid
                style={{
                  borderStyle: 'solid',
                  borderWidth: '0px 0px 1px 0px',
                  borderColor: 'rgba(50,50,50,0.3)',
                }}
                item
                xs={1}
              >
                <Typography align="center">
                  {formik.values.immutableData?.taxes?.[index]?.name}
                </Typography>
              </Grid>
              <Grid item xs={1} />
              <Grid
                style={{
                  borderStyle: 'solid',
                  borderWidth: '0px 0px 1px 0px',
                  borderColor: 'rgba(50,50,50,0.3)',
                }}
                item
                xs={1}
              >
                <Typography align="center" variant="h6">
                  Descrição:
                </Typography>
              </Grid>
              <Grid
                style={{
                  borderStyle: 'solid',
                  borderWidth: '0px 0px 1px 0px',
                  borderColor: 'rgba(50,50,50,0.3)',
                }}
                item
                xs={1}
              >
                <Typography align="center">
                  {formik.values.immutableData?.taxes?.[index]?.description}
                </Typography>
              </Grid>
              <Grid item xs={1} />
              <Grid
                style={{
                  borderStyle: 'solid',
                  borderWidth: '0px 0px 1px 0px',
                  borderColor: 'rgba(50,50,50,0.3)',
                }}
                item
                xs={1}
              >
                <Typography align="center" variant="h6">
                  Tipo:
                </Typography>
              </Grid>
              <Grid
                style={{
                  borderStyle: 'solid',
                  borderWidth: '0px 0px 1px 0px',
                  borderColor: 'rgba(50,50,50,0.3)',
                }}
                item
                xs={1}
              >
                <Typography align="center">
                  {formik.values.immutableData?.taxes?.[index]?.type}
                </Typography>
              </Grid>
              <Grid item xs={1} />
              <Grid 
                style={{
                  borderStyle: 'solid',
                  borderWidth: '0px 0px 1px 0px',
                  borderColor: 'rgba(50,50,50,0.3)',
                }}
                item
                xs={1}
              >
                <Typography align="center" variant="h6">
                  Valor:
                </Typography>
              </Grid>
              <Grid
                style={{
                  borderStyle: 'solid',
                  borderWidth: '0px 0px 1px 0px',
                  borderColor: 'rgba(50,50,50,0.3)',
                }}
                item
                xs={1}
              >
                <Typography align="center">
                  {formik.values.immutableData?.taxes?.[index]?.value}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
        {/* <Grid item xs={12}>
                <Typography variant="h4">
                  Descontos
                </Typography>
              </Grid>
              {formik.values.immutableData?.credits?.map((c: any, index: number) => (
                <Grid item xs={12} key={`credit_${index}`} container spacing={2}>
                  <Grid item xs={1}>
                    <TextField
                      fullWidth
                      autoFocus
                      variant="standard"
                      type="number"
                      name={`credit.[${index}].value`}
                      label="Valor"
                      value={c.value ?? ''}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.credit)}
                      helperText={formik.errors.credit}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <FormControl fullWidth>
                      <InputLabel id="creditTypeLabel">Modelo</InputLabel>
                      <Select
                        labelId="type"
                        label="Tipo do Imposto"
                        name={`credit.[${index}].creditType`}
                        variant="standard"
                        value={c.creditType ?? ''}
                        onChange={value => formik.handleChange(value)}
                        error={Boolean(formik.errors.credit)}
                      >
                        <MenuItem value="CREDIT">CREDITO</MenuItem>
                        <MenuItem value="DISCOUNT">DESCONTO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                    <FormControl fullWidth>
                      <InputLabel id="valueModelLabel">Cobrança</InputLabel>
                      <Select
                        labelId="valueModel"
                        label="Tipo do Imposto"
                        name={`credit.[${index}].valueModel`}
                        variant="standard"
                        value={c.valueModel ?? ''}
                        onChange={value => formik.handleChange(value)}
                        error={Boolean(formik.errors.credit)}
                      >
                        <MenuItem value="FIXED">FIXO</MenuItem>
                        <MenuItem value="PERCENT">PORCENTO</MenuItem>
                        <MenuItem value="LIMITED">LIMITADO</MenuItem>

                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                    <FormControl fullWidth>
                      <InputLabel id="currency">Moeda</InputLabel>
                      <Select
                        labelId="currency"
                        label="Moeda"
                        name={`credit.[${index}].currency`}
                        variant="standard"
                        value={c.currency ?? ''}
                        onChange={value => formik.handleChange(value)}
                        error={Boolean(formik.errors.credit)}
                      >
                        <MenuItem value="BRL">R$ - BRL</MenuItem>
                        <MenuItem value="USD">US$ - USD</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                    <FormControl fullWidth>
                      <InputLabel id="currencyType">Cobrança Moeda</InputLabel>
                      <Select
                        labelId="currencyType"
                        label="Tipo do Imposto"
                        name={`credit.[${index}].currencyType`}
                        variant="standard"
                        value={c.currencyType ?? ''}
                        onChange={value => formik.handleChange(value)}
                        error={Boolean(formik.errors.credit)}
                      >
                        <MenuItem value={formik.values.dollarType}>VIGENTE</MenuItem>
                        <MenuItem value="PTAX">PTAX</MenuItem>
                        <MenuItem value="LPTAX">LPTAX</MenuItem>
                        <MenuItem value="FIXED">FIXED</MenuItem>

                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      fullWidth
                      autoFocus
                      variant="standard"
                      type="number"
                      name={`credit.[${index}].currencyValue`}
                      label="Valor da Moeda"
                      InputProps={{ inputProps: { min: 0, max: 99 } }}
                      value={c.currencyValue ?? ''}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.currencyValue)}
                      helperText={formik.errors.currencyValue}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      fullWidth
                      autoFocus
                      variant="standard"
                      type="number"
                      name={`credit.[${index}].remainingMonths`}
                      label="Meses Restantes"
                      InputProps={{ inputProps: { min: 0, max: 99 } }}
                      value={c.remainingMonths ?? ''}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.remainingMonths)}
                      helperText={formik.errors.remainingMonths}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      autoFocus
                      variant="standard"
                      name={`credit.[${index}].description`}
                      label="Descrição"
                      value={c.description ?? ''}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.credit)}
                      helperText={formik.errors.credit}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={c.active}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      )}
                      label="ATIVO"
                    />

                  </Grid>
                </Grid>
              ))}
            </Grid>
              <Grid item container spacing={2} xs={12} justifyContent="flex-end">
                <Grid item xs={12}>
                  <Typography variant="h4">
                    Observações
                  </Typography>
                </Grid>
                {formik.values.immutableData?.observations.map((desc: any, index: number) => (
                  <Grid item xs={12} key={`desc_${index}`} container spacing={2}>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        autoFocus
                        variant="standard"
                        name={`data.observations.[${index}].message`}
                        label="Observação"
                        value={formik.values.immutableData?.observations?.[index].message ?? ''}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.data)}
                        helperText={formik.errors.data}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <FormControlLabel
                        control={(
                          <Switch
                            name={`data.observations.[${index}].type`}
                            checked={desc.type === 'INTERNAL'}
                            onChange={() => { handleSwitch(index); }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        )}
                        label="Interno"
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Button size="large" onClick={removeDiscount(index)} variant="contained">
                        ✗
                      </Button>
                    </Grid>
                  </Grid>

                ))}
                <Grid item container justifyContent="center">
                  <Button size="large" onClick={handleObservation}>
                    Adicionar Comentário
                  </Button>
                </Grid>

             */}

        <Grid item container spacing={2} xs={12} justifyContent="flex-end">
          <Grid item>
            <Button size="large" onClick={navigateBack}>
              Voltar
            </Button>
          </Grid>
          {(isOwner(formik.values.contract?.sellerId) || isAdmin) && (
            <Grid item>
              <Button size="large" onClick={downloadINV} variant="contained">
                Download Pedido
              </Button>
            </Grid>
          )}
          {/* {<Grid item> */}
          {/*   <Button disabled={formik.values.nfeStatus !== 'APPROVED' || formik.values.nfeStatus !== 'CANCELED'} */}
          {/*           size="large" onClick={downloadNFE} variant="contained"> */}
          {/*     Download NFE */}
          {/*   </Button> */}
          {/* </Grid>} */}
        </Grid>
      </Grid>
    </form>
  );
};

export default InvoiceForm;
