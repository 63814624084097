import { FetchResult, MutationFunctionOptions } from '@apollo/client';

export interface FormState {
  updateMutation?: (options: MutationFunctionOptions) => Promise<FetchResult<any>>,
  createMutation?: (options: MutationFunctionOptions) => Promise<FetchResult<any>>,
  mutationValues?: object
}

export const initialState:FormState = {};

const SET_LINKS = 'partner-portal/form/SET_LINKS';
const SET_MUTATION_VALUES = 'partner-portal/form/SET_MUTATION_VALUES';

export function setLinks(payload: object) {
  return {
    type: SET_LINKS,
    payload
  };
}
export function setMutationValues(payload:object) {
  return {
    type: SET_MUTATION_VALUES,
    payload
  };
}

export default function reducer(state = initialState, action: { type: string | string[], payload: Partial<FormState> }) {
  switch (action.type) {
    case SET_LINKS: {
      return {
        ...state,
        createMutation: action.payload.createMutation,
        updateMutation: action.payload.updateMutation,
      };
    }
    case SET_MUTATION_VALUES:
      console.log(action.payload);
      return { ...state, mutationValues: { ...state.mutationValues, ...action.payload } };
    default:
      return state;
  }
}
