import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import Carousel from '../../components/carousel';
import Container from '@mui/material/Container';
import { useQuery } from '@apollo/client';
import { GET_USER_PROFILE } from '../../gql/users';
import { GridContainerCenter } from './style';

const Home = () => {
  const { data } = useQuery(GET_USER_PROFILE);

  return (
    <Container maxWidth="xl">
      <Grid container justifyContent="center">
        <GridContainerCenter item container xs={12} md={6}>
          <TextField
            sx={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}
            style={{ border: 'none', borderRadius: '10%' }}
            fullWidth
            label="O que você procura"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button>
                    <SearchIcon />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </GridContainerCenter>
        <Grid container item sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h5">
            Bem vindo ao portal de parceiros,{' '}
            <Typography
              component="span"
              variant="h5"
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              {' '}
              {data?.me?.firstName}
            </Typography>
          </Typography>
        </Grid>
        <GridContainerCenter item md={12} justifyContent="flex-start">
          <Carousel />
        </GridContainerCenter>
        <GridContainerCenter item md={12} sx={{ mt: 6 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              '& > :not(style)': {
                width: 'calc(50% - 8px)',
                height: 400,
                marginBottom: 6,
              },
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              '& > :not(style)': {
                width: 'calc(100% - 0px)',
                height: 400,
                marginBottom: '16px',
              },
            }}
          />

        </GridContainerCenter>
      </Grid>
    </Container>
  );
};

export default Home;
