import * as React from 'react';
import Box from '@mui/material/Box';
import moment from 'moment/moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';

type YearMonthPickerProps = Omit<DatePickerProps<any, any>, 'renderInput'>;

const YearMonthPicker = (
  props: YearMonthPickerProps & React.RefAttributes<HTMLDivElement>
) => {
  const { onChange, label = '', disabled, ...otherProps } = props;
  const handleChange = (date: any) => {
    onChange(moment(date).set('date', 1).toISOString());
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        disabled={disabled}
        {...(otherProps as DatePickerProps<any, any>)}
        onChange={handleChange}
        views={['month', 'year']}
        renderInput={({ inputProps, InputProps, inputRef }: Record<any, any>) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <strong ref={inputRef}>
              ({label} até {moment(inputProps.value).format('MM/YYYY')})
            </strong>
            {InputProps?.endAdornment}
          </Box>
        )}
      />
    </LocalizationProvider>
  );
};

export default YearMonthPicker;
