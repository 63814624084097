import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import GenericTable from '../genericTable';

const rows = [
  {
    id: 1,
    user: 'João Keuwe',
  },
  {
    id: 2,
    user: 'Wesley Silva',
  },
];

const columnOptions = {
  id: {
    flex: 1,
  },
  user: {
    flex: 1,
    headerName: 'Usuários',
  }
}

const tableUsers = () => (
  <Grid container item md={12}>
    <Paper
      sx={{
        width: '100%',
        mb: 2,
      }}
    >
      <GenericTable
        data={rows}
        loading={false}
        columnOptions={columnOptions}
        hideDeleteButton
      />
    </Paper>
  </Grid>
);

export default tableUsers;
