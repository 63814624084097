import { createSelector } from 'reselect';
import { RootStateOrAny } from 'react-redux';

const drawerSizes = {
  open: 264,
  closed: 64
};

const convertDrawerWidth = (currentValue: boolean) => (currentValue ? drawerSizes.open : drawerSizes.closed);

// Drawer
export const selectDrawerWidth = createSelector(({ app }: RootStateOrAny) => app.isDrawerOpen, (isDrawerOpen: boolean) => convertDrawerWidth(isDrawerOpen));
export const selectIsDrawerOpen = createSelector(({ app }: RootStateOrAny) => app.isDrawerOpen, isDrawerOpen => isDrawerOpen);


// Alerts
export const selectAlerts = createSelector(({ app }: RootStateOrAny) => app.displayAlert, ({ app }: RootStateOrAny) => app.currentAlert, (displayAlert, currentAlert) => ({ displayAlert, currentAlert }));


// Loading
export const selectGlobalLoading = createSelector(({ app }: RootStateOrAny) => app.globalLoading, globalLoading => globalLoading);
