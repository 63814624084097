import * as yup from 'yup';
import { requiredField } from '../../../utils/forms';

const partnerSchema = yup.object({
  id: yup
    .string()
    .label('id'),
  street: yup
    .string()
    .required(requiredField('Rua')),
  number: yup
    .number()
    .required(requiredField('Numero')),
  complement: yup
    .string()
    .notRequired(),
  city: yup
    .string()
    .required(requiredField('Cidade')),
  zipcode: yup
    .string()
    .required(requiredField('CEP')),
  companyName: yup
    .string()
    .required(requiredField('Razão Social')),
  tradingName: yup
    .string()
    .required(requiredField('Nome Fantasia')),
  contactName: yup
    .string()
    .required(requiredField('Nome de contato')),
  contactNumber: yup
    .string()
    .required(requiredField('Numero de Contato')),
  district: yup
    .string()
    .required(requiredField('Bairro')),
  telephone: yup
    .string()
    .required(requiredField('Telephone')),
  state: yup
    .string()
    .required(requiredField('Estado')),
  email: yup
    .string()
    .email()
    .required(requiredField('Email')),
  contactEmail: yup
    .string()
    .email()
    .required(requiredField('Email de contato')),
  cnpj: yup
    .string()
    .required(requiredField('CNPJ')),
  website: yup
    .string()
});

export default partnerSchema;
