import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import ResponsiveCardMedia from './style';
import image1 from '../../assets/images/image1.png';
import image2 from '../../assets/images/image2.png';
import image3 from '../../assets/images/image3.png';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme: any) => ({
  root: {
    maxWidth: '100%',
    margin: '0 auto',
    position: 'relative',
  },
  // media: {
  //   height: 400,
  //   width: '100%',
  // },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    marginBottom: theme.spacing(2),
  },
  paginationItem: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: 'rgba(217, 217, 217, 1)',
    margin: theme.spacing(0, 1),
    cursor: 'pointer',
  },
  paginationItemActive: {
    backgroundColor: 'rgba(45, 68, 144, 1)',
  },
}));

const Carousel: React.FC = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const carouselItems = [
    {
      image: image1,
    },
    {
      image: image2,
    },
    {
      image: image3,
    },
  ];

  return (
    <Box className={classes.root}>
      <AutoPlaySwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        interval={5000}
        enableMouseEvents
      >
        {carouselItems.map((item, index) => (
          <Card key={index}>
            <CardActionArea>
              <ResponsiveCardMedia image={item.image} />
            </CardActionArea>
          </Card>
        ))}
      </AutoPlaySwipeableViews>
      <Box className={classes.pagination}>
        {carouselItems.map((_, index) => (
          <Box
            key={index}
            className={`${classes.paginationItem} ${
              index === activeStep ? classes.paginationItemActive : ''
            }`}
            onClick={() => handleStepChange(index)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Carousel;
