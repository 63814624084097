import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation login($userCredentials: LoginUserInput!) {
    login(loginUserInput: $userCredentials) {
      access_token
      refresh_token
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;
