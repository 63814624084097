import React, { useMemo } from 'react';
import Alert from '@mui/material/Alert';
import Tab from '@mui/material/Tab';
import Link from '@mui/material/Link';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { GET_ALL_ALERTS } from '../../gql/alerts';
import { PaperAlert, TypographyAlerts, BoxScrollBar, BoxAlerts } from './style';
import { useQuery } from '@apollo/client';

type AlertType = {
  severity: 'error' | 'warning' | 'info';
  message: string;
};

const AlertsMenu = () => {
  const { data } = useQuery(GET_ALL_ALERTS);
  const alerts = useMemo(() => data?.getAllAlerts || [], [data]);

  const alertTypes: Record<string, AlertType> = {
    ERROR: { severity: 'error', message: 'Alerta de erro detectato!' },
    WARNING: { severity: 'warning', message: 'Alerta de Aviso detectada!' },
    INFO: { severity: 'info', message: 'Alerta Informativo detectado!' },
  };

  let content;

  if (alerts && alerts.length > 0) {
    content = `${alerts.length} ativos`;
  } else {
    content = 'Nenhum Alerta Ativo';
  }

  return (
    <PaperAlert>
      <TabContext value="1">
        <TabList>
          <Tab label="Alertas" value="1" />
        </TabList>
        <Link href="/dashboard/alerts">
          <TypographyAlerts variant="body1">
            Todos os alertas ({content})
          </TypographyAlerts>
        </Link>
      </TabContext>

      <BoxScrollBar>
        <TabContext value="1">
          <BoxAlerts>
            {alerts?.map((alert: any) => (
              <Alert
                key={alert.id}
                severity={alertTypes[alert.level].severity}
                variant="outlined"
              >
                {alertTypes[alert.level].message}
              </Alert>
            ))}
          </BoxAlerts>
        </TabContext>
      </BoxScrollBar>
    </PaperAlert>
  );
};

export default AlertsMenu;
