import * as yup from 'yup';
import { requiredField } from '../../../utils/forms';

const userSchema = yup.object({
  id: yup
    .string()
    .label('id'),
  firstName: yup
    .string()
    .label('Nome')
    .required(requiredField('Nome')),
  lastName: yup
    .string()
    .label('Sobrenome')
    .required(requiredField('Sobrenome')),
  email: yup
    .string()
    .email()
    .required(requiredField('Email')),
  password: yup
    .string()
    .min(8),
  cpf: yup
    .string()
    .label('CPF'),
  partnerId: yup
    .string()
    .required(requiredField('partnerId')),
  role: yup
    .number()
    .required(requiredField('role')),
});

export default userSchema;
