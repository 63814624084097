import React from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';

interface CustomTabsProps{
   tabs: TabProps[];
  onChange: any;
}
interface TabProps {
  name: string;
  component: any;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{
        borderWidth: 1,
        borderColor: 'rgba(30,30,30,0.3)',
        borderStyle: 'solid',
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box width="100%" p={1}>
          {children}
        </Box>
      )}
    </Box>
  );
}


const CustomTabs: React.FC<CustomTabsProps> = ({ tabs, onChange }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChange();
    setValue(newValue);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {tabs.map(t => (
            <Tab label={t.name} key={`tab_${t.name}`} />
          ))}
        </Tabs>
        {tabs.map((t, i) => (
          <TabPanel key={`tabPanel_${t.name}`} value={value} index={i}>{t.component}</TabPanel>

        ))}
      </Box>
    </Box>
  );
};

export default CustomTabs;
