import { gql } from '@apollo/client';

export const GET_ALL_ALERTS = gql`
  query getAllAlerts {
    getAllAlerts {
      count
      description
      hash
      id
      lastOccurrence
      level
      status
      tenantId
    }
  }
`;

export const GET_ALERTS_BY_ID = gql`
  query getAlertById($id: String!) {
    getAlertById(id: $id) {
      id
      description
      hash
      lastOccurrence
      level
      status
      tenantId
      count
    }
  }
`;

export const DELETE_ALERT = gql`
  mutation removeAlert($id: String!) {
    removeAlert(id: $id)
  }
`;
