import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/header';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import manufacturerSchema from './schema';
import Grid from '@mui/material/Grid';
import {
  GET_ALL_MANUFACTURERS,
  GET_MANUFACTURER_BY_ID,
  UPDATE_MANUFACTURER,
  CREATE_MANUFACTURER,
} from '../../../gql/manufacturers';
import Button from '@mui/material/Button';
import useSnackbar from '../../../hooks/useSnackbar';
import { getTenantId } from '../../../services/tenancy';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const ManufacturerForm = () => {
  const { id } = useParams();
  const isCreate = id === 'create';
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [mutateManufacturer] = useMutation(
    isCreate ? CREATE_MANUFACTURER : UPDATE_MANUFACTURER,
    { refetchQueries: [{ query: GET_ALL_MANUFACTURERS }] }
  );
  const [getCurrentManufacturer, { data }] = useLazyQuery(GET_MANUFACTURER_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  const navigateBack = () => navigate('/dashboard/invoices');

  const handleSubmit = () => {
    snackbar.setGlobalLoading(true);
    const { values } = formik;
    snackbar.sendAlert({ level: 'info', message: 'Salvando Dados...' });

    const input: Record<string, any> = { tenantId: getTenantId() };
    Object.keys(manufacturerSchema.fields).map((key) => {
      if (Object.hasOwn(values, key)) input[key] = values[key];
      return null;
    });
    mutateManufacturer({ variables: { input } })
      .then(() =>
        snackbar.sendAlert({ level: 'success', message: 'Dados salvos com sucesso ' })
      )
      .catch((er) => {
        snackbar.sendAlert({ level: 'error', message: 'Erro Salvando dados' });
        console.error(er);
      })
      .finally(() => {
        snackbar.setGlobalLoading(false);
        navigateBack();
      });
  };

  const [displayEditModal, setDisplayEditModal] = React.useState(false);
  const handleCloseModal = () => {
    setDisplayEditModal(false);
  };

  const handleOpenModal = () => {
    setDisplayEditModal(true);
  };
  const formik = useFormik({
    initialValues: data?.getManufacturerById
      ? {
          ...data.getManufacturerById,
          manufacturerId: data.getManufacturerById?.manufacturer?.id,
        }
      : {},
    validationSchema: manufacturerSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!isCreate) getCurrentManufacturer({ variables: { id } });
  }, [isCreate]);

  return (
    <>
      <Header title={`${isCreate ? 'Criar' : 'Editar'} Fabricante`} />
      <Paper sx={{ width: '100%', mb: 2, p: 2 }}>
        <form onSubmit={formik.handleSubmit}>
          <Dialog
            open={displayEditModal}
            onClose={handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Editar Fabricante</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              Você tem certeza que gostaria de salvar essa edição?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid>
                <Button onClick={handleCloseModal}>Cancelar</Button>
                <Button type="submit" onClick={handleSubmit}>
                  Salvar
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
          <Grid container spacing={4}>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  autoFocus
                  variant="standard"
                  name="name"
                  label="Nome do Fabricante"
                  value={formik.values.name ?? ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.name)}
                  helperText={formik.errors.name}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12} justifyContent="flex-end">
              <Grid item>
                <Button size="large" onClick={navigateBack}>
                  Cancelar
                </Button>
              </Grid>
              {!isCreate ? (
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    type="button"
                    onClick={handleOpenModal}
                  >
                    Salvar
                  </Button>
                </Grid>
              ) : (
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Salvar
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default ManufacturerForm;
