import CardMedia from '@mui/material/CardMedia';
import styled from 'styled-components';

const ResponsiveCardMedia = styled(CardMedia)`
  @media (max-width: 1600px) {
    height: 350px;
  }

  @media (max-width: 1420px) {
    height: 284px;
  }

  @media (max-width: 1190px) {
    height: 264px;
  }

  @media (max-width: 1100px) {
    height: 220px;
  }
  background-size: cover;
  width: 100%;
  height: 400px;
`;

export default ResponsiveCardMedia;
