import { combineReducers } from 'redux';
import app from './app';
import tabs from './tabs';
import menu from './form';

export default function createReducer(reducers?: object) {
  return combineReducers({
    app,
    tabs,
    menu,
    ...reducers
  });
}
