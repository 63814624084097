import React, { Fragment, memo, useEffect, useMemo, useState } from 'react';
import Paper from '@mui/material/Paper';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/header';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import partnerSchema from './schema';
import LoadingScreen from '../../../components/loadingScreen';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Avatar from '@mui/material/Avatar';
import InputMask from 'react-input-mask';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { invoiceStatus, ufList } from '../../../utils/forms';
import {
  CREATE_PARTNER,
  GET_ALL_PARTNERS,
  GET_PARTNER_BY_ID,
  UPDATE_PARTNER,
} from '../../../gql/partners';
import Button from '@mui/material/Button';
import useSnackbar from '../../../hooks/useSnackbar';
import { getTenantId } from '../../../services/tenancy';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import GenericTable from '../../../components/genericTable';
import debounce from 'debounce';
import { GridRenderCellParams } from '@mui/x-data-grid';
import UserForm from '../../users/form';
import CustomTabs from '../../../components/tabs';
import { formatToTimeZone } from 'date-fns-timezone';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import InvoiceForm from '../../invoices/form';
import ContractForm from '../../contracts/form';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Role } from '../../../utils/roles';
import usePermissions from '../../../hooks/usePermissions';

const timezone = { timeZone: 'UTC' };

const columnsOptions = {
  id: {
    hide: true,
  },
  firstName: {
    headerName: 'Icon',
    renderCell: (params: GridRenderCellParams<Record<any, any>>) => (
      // eslint-disable-next-line implicit-arrow-linebreak
      <Avatar>{`${params.row?.firstName[0]}${params.row?.lastName[0]}`}</Avatar>
    ),
  },
  lastName: {
    headerName: 'Nome',
    valueGetter: ({ row }: Record<any, any>) => `${row.firstName} ${row.lastName}`,
  },
  email: {
    headerName: 'Email',
  },
  role: {
    headerName: 'Cargo',
    valueGetter: ({ value }: Record<any, any>) => Role[value],
  },
};
const contractColumnsOptions = {
  id: {
    hide: true,
  },
  seller: {
    headerName: 'Gerente',
    valueGetter: ({ value }: Record<any, any>) => `${value.firstName} ${value.lastName}`,
  },
  product: {
    headerName: 'Produto',
    valueGetter: ({ value }: Record<any, any>) => value.name,
  },
  product_tier: {
    headerName: 'Tier',
  },
};
const invoicesColumnsOptions = {
  id: {
    hide: true,
  },
  startDate: {
    hide: true,
  },
  saleId: {
    position: 0,
    headerName: '# da venda',
  },
  status: {
    headerName: 'Status',
    valueGetter: ({ value }: Record<any, any>) => invoiceStatus[value],
  },
  endDate: {
    headerName: 'Periodo Faturamento',
    valueGetter: ({ row }: any) =>
      `${formatToTimeZone(
        new Date(Number(row.startDate)),
        'DD/MM/YYYY',
        timezone
      )} à ${formatToTimeZone(new Date(Number(row.endDate)), 'DD/MM/YYYY', timezone)}`,
  },
  dueDate: {
    headerName: 'Vencimento',
    valueGetter: ({ value }: any) =>
      value
        .map(
          (v: any) => `${formatToTimeZone(new Date(Number(v)), 'DD/MM/YYYY', timezone)}`
        )
        .join(' | '),
  },
  totalValue: {
    headerName: 'Total',
  },
};

const PartnerForm = (onDelete: any) => {
  const { id } = useParams();
  const isCreate = id === 'create';
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [uid, setUid] = useState<string>();
  const [sid, setSid] = useState<string>();
  const [cid, setCid] = useState<string>();

  const [mutatePartner] = useMutation(isCreate ? CREATE_PARTNER : UPDATE_PARTNER, {
    refetchQueries: [{ query: GET_ALL_PARTNERS }],
  });
  const [getCurrentPartner, { loading, data }] = useLazyQuery(GET_PARTNER_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  const [displayEditModal, setDisplayEditModal] = React.useState(false);
  const handleCloseModal = () => {
    setCurrentEntity(undefined);
    setDisplayEditModal(false);
  };
  const [currentEntity, setCurrentEntity] = React.useState<Record<any, any> | undefined>(
    undefined
  );

  const handleDelete = () => {
    if (currentEntity) onDelete?.(currentEntity);
  };
  const handleOpenModal = () => {
    setDisplayEditModal(true);
  };
  const navigateBack = () => navigate('/dashboard/users');
  const handleEditUser = (row: any) => {
    setUid(row.id);
  };
  const handleEditSaleOrder = (row: any) => {
    setSid(row.id);
  };
  const handleEditContract = (row: any) => {
    setCid(row.id);
  };
  const handleSubmit = () => {
    snackbar.setGlobalLoading(true);
    const { values } = formik;

    snackbar.sendAlert({ level: 'info', message: 'Salvando Dados...' });

    const input: Record<string, any> = { tenantId: getTenantId() };
    Object.keys(partnerSchema.fields).map((key) => {
      if (Object.hasOwn(values, key)) input[key] = values[key];
      return null;
    });

    mutatePartner({ variables: { input } })
      .then(() =>
        snackbar.sendAlert({ level: 'success', message: 'Dados salvos com sucesso ' })
      )
      .catch((er) => {
        snackbar.sendAlert({ level: 'error', message: 'Erro Salvando dados' });
        console.error(er);
      })
      .finally(() => {
        snackbar.setGlobalLoading(false);
        navigateBack();
      });
  };
  const handleChangeAutoComplete = (_: unknown, value: Record<string, string>) => {
    formik.setFieldValue('state', value);
  };
  const getCep = ({ target }: React.FocusEvent<HTMLInputElement>) => {
    if (target.value.length === 9) {
      fetch(`https://viacep.com.br/ws/${target.value}/json/`)
        .then((res) => res.json())
        .then((r) => {
          if (!r.erro) {
            formik.setFieldValue('zipcode', r?.cep?.replace('-', ''));
            formik.setFieldValue('district', formik.values.district || r.bairro);
            formik.setFieldValue('street', formik.values.street || r.logradouro);
            formik.setFieldValue('city', formik.values.city || r.localidade);
            formik.setFieldValue('state', formik.values.state || r.uf);
          } else {
            formik.setFieldValue('zipcode', String(target.value.replace(/[^\d]/g, '')));
          }
        });
    }
  };
  const { isAdmin } = usePermissions();
  const permissionsAdmin = isAdmin;

  const formik = useFormik({
    initialValues: data?.getPartnerById
      ? {
          ...data.getPartnerById,
          partnerId: data.getPartnerById?.partner?.id,
        }
      : {},
    validationSchema: partnerSchema,
    validateOnMount: false,
    validateOnChange: true,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  // const customUserTable = (users: any[]) => users.map(user => (
  //   {
  //     columns: Object.keys(user).map(field => (
  //       {
  //         type: field === 'id' ? 'IMAGE' : 'STRING',
  //         value: user[field],
  //         align: 'center',
  //         onClick: () => {}
  //       }))
  //   }
  // ));
  const debouncedValidate = useMemo(
    () => debounce(formik.validateForm, 500),
    [formik.validateForm]
  );
  useEffect(() => {
    debouncedValidate(formik.values);
  }, [formik.values, debouncedValidate]);

  useEffect(() => {
    if (!isCreate) getCurrentPartner({ variables: { id } });
  }, [isCreate]);
  return (
    <>
      <Dialog
        open={displayEditModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Editar Parceiro</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem certeza que gostaria de salvar essa edição?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid>
            <Button onClick={handleCloseModal}>Cancelar</Button>
            <Button type="submit" onClick={handleSubmit}>
              Salvar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      {loading && <LoadingScreen />}
      <Header title={`${isCreate ? 'Criar' : 'Editar'} Parceiro`} />
      <Paper sx={{ width: '100%', mb: 2, p: 2 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4} justifyContent="center">
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth variant="standard">
                  <InputMask
                    mask="99.999.999/9999-99"
                    value={formik.values.cnpj ?? ''}
                    disabled={false}
                    onChange={({ target }) => {
                      formik.setFieldValue(
                        'cnpj',
                        String(target.value.replace(/[^\d]/g, ''))
                      );
                    }}
                  >
                    <TextField
                      fullWidth
                      autoFocus
                      placeholder="00.000.000/0000-00"
                      error={Boolean(formik.errors.cnpj)}
                      name="cnpj"
                      id="cnpj-mask"
                      variant="standard"
                      label="CNPJ"
                      helperText={formik.errors.cnpj}
                    />
                  </InputMask>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  autoFocus
                  id="companyName"
                  variant="standard"
                  label="Razão Social"
                  name="companyName"
                  value={formik.values.companyName ?? ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.companyName)}
                  helperText={formik.errors.companyName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Nome Fantasia"
                  name="tradingName"
                  value={formik.values.tradingName ?? ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.tradingName)}
                  helperText={formik.errors.tradingName}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant="standard">
                  <InputMask
                    mask="+(55) 99 99999-9999"
                    value={formik.values.telephone ?? ''}
                    disabled={false}
                    onChange={({ target }) => {
                      formik.setFieldValue(
                        'telephone',
                        String(target.value.replace(/[^\d]/g, ''))
                      );
                    }}
                  >
                    <TextField
                      fullWidth
                      autoFocus
                      placeholder="+(55) 11 00000-0000"
                      error={Boolean(formik.errors.telephone)}
                      name="telephone"
                      id="tel-mask"
                      variant="standard"
                      label="Telefone"
                      helperText={formik.errors.telephone}
                    />
                  </InputMask>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Email Corporativo"
                  name="email"
                  value={formik.values.email ?? ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.email)}
                  helperText={formik.errors.email}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Website"
                  name="website"
                  value={formik.values.website ?? ''}
                  onChange={formik.handleChange}
                  helperText="Insira o website do parceiro"
                />
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={6}>
              <Divider textAlign="left" sx={{ m: 1, pt: 6 }}>
                <Typography variant="h5">Endereço</Typography>
              </Divider>
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard">
                  <InputMask
                    mask="99999-999"
                    value={formik.values.zipcode ?? ''}
                    disabled={false}
                    onChange={({ target }) => {
                      formik.setFieldValue(
                        'zipcode',
                        String(target.value.replace(/[^\d]/g, ''))
                      );
                    }}
                    onBlur={getCep}
                  >
                    <TextField
                      fullWidth
                      autoFocus
                      placeholder="00000-000"
                      error={Boolean(formik.errors.zipcode)}
                      name="zipcode"
                      variant="standard"
                      label="CEP"
                      helperText={formik.errors.zipcode}
                    />
                  </InputMask>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Endereco"
                  name="street"
                  value={formik.values.street ?? ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.street)}
                  helperText={formik.errors.street}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Numero"
                  name="number"
                  type="number"
                  value={formik.values.number ?? ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.number)}
                  helperText={formik.errors.number}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Complemento"
                  name="complement"
                  value={formik.values.complement ?? ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.complement)}
                  helperText={formik.errors.complement}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Bairro"
                  name="district"
                  value={formik.values.district ?? ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.district)}
                  helperText={formik.errors.district}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Cidade"
                  name="city"
                  value={formik.values.city ?? ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.city)}
                  helperText={formik.errors.city}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="state"
                  options={ufList}
                  value={formik.values.state ?? ''}
                  onChange={handleChangeAutoComplete}
                  renderInput={(params) => (
                    <TextField name="state" variant="standard" {...params} label="UF" />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container item xs={6} spacing={2} alignSelf="start">
              <Divider textAlign="left" sx={{ m: 1, pt: 6 }}>
                <Typography variant="h5">Representante/Contato</Typography>
              </Divider>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  autoFocus
                  variant="standard"
                  label="Nome"
                  name="contactName"
                  value={formik.values.contactName ?? ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.contactName)}
                  helperText={formik.errors.contactName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Email"
                  name="contactEmail"
                  value={formik.values.contactEmail ?? ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.contactEmail)}
                  helperText={formik.errors.contactEmail}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard">
                  <InputMask
                    mask="+(55) 99 99999-9999"
                    value={formik.values.contactNumber ?? ''}
                    disabled={false}
                    onChange={({ target }) => {
                      formik.setFieldValue(
                        'contactNumber',
                        String(target.value.replace(/[^\d]/g, ''))
                      );
                    }}
                  >
                    <TextField
                      fullWidth
                      autoFocus
                      placeholder="+(55) 11 00000-0000"
                      error={Boolean(formik.errors.contactNumber)}
                      name="contactNumber"
                      variant="standard"
                      label="Telefone"
                      helperText={formik.errors.contactNumber}
                    />
                  </InputMask>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end">
              <Grid>
                {permissionsAdmin ? (
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={handleDelete}
                  >
                    <DeleteIcon fontSize="small" style={{ marginRight: 5 }} /> Remover
                    Parceiro
                  </Button>
                ) : null}
              </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12} justifyContent="flex-end">
              <Grid item>
                <Button size="large" onClick={navigateBack}>
                  Cancelar
                </Button>
              </Grid>
              {!isCreate ? (
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    type="button"
                    style={{ display: permissionsAdmin ? 'block' : 'none' }}
                    onClick={handleOpenModal}
                  >
                    Salvar
                  </Button>
                </Grid>
              ) : (
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Salvar
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTabs
              onChange={() => {
                setUid(undefined);
                setSid(undefined);
                setCid(undefined);
              }}
              tabs={[
                {
                  name: 'Contratos',
                  component: !cid ? (
                    <GenericTable
                      data={formik.values.contracts}
                      loading={loading}
                      onEdit={handleEditContract}
                      columnOptions={contractColumnsOptions}
                      customOperations={[
                        {
                          icon: <RemoveRedEyeIcon color="primary" />,
                          onClick: handleEditContract,
                          label: 'Visualizar',
                        },
                      ]}
                    />
                  ) : (
                    <Fragment>
                      <Button
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setCid(undefined);
                        }}
                      >
                        <ChevronLeft fontSize="medium" />
                        <Typography fontWeight="600" fontSize={18}>
                          Retornar
                        </Typography>
                      </Button>
                      <ContractForm uid={uid} />
                    </Fragment>
                  ),
                },
                {
                  name: 'Pedidos de Venda',
                  component: !sid ? (
                    <GenericTable
                      data={formik.values.invoices}
                      loading={loading}
                      onEdit={handleEditSaleOrder}
                      columnOptions={invoicesColumnsOptions}
                      customOperations={[
                        {
                          icon: <RemoveRedEyeIcon color="primary" />,
                          onClick: handleEditSaleOrder,
                          label: 'Visualizar',
                        },
                      ]}
                    />
                  ) : (
                    <Fragment>
                      <Button
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setCid(undefined);
                        }}
                      >
                        <ChevronLeft fontSize="medium" />
                        <Typography fontWeight="600" fontSize={18}>
                          Retornar
                        </Typography>
                      </Button>
                      <InvoiceForm uid={sid} />
                    </Fragment>
                  ),
                },
                {
                  name: 'Usuários',
                  component: !uid ? (
                    <GenericTable
                      dense
                      data={formik.values.users}
                      loading={loading}
                      onEdit={handleEditUser}
                      customOperations={[
                        {
                          icon: <RemoveRedEyeIcon color="primary" />,
                          onClick: handleEditUser,
                          label: 'Visualizar',
                        },
                      ]}
                      columnOptions={columnsOptions}
                    />
                  ) : (
                    <Fragment>
                      <Button
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setUid(undefined);
                        }}
                      >
                        <ChevronLeft fontSize="medium" />
                        <Typography fontWeight="600" fontSize={18}>
                          Retornar
                        </Typography>
                      </Button>
                      <UserForm uid={uid} />
                    </Fragment>
                  ),
                },
              ]}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default memo(PartnerForm);
