import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/header';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import validationSchema from './schema';
import Grid from '@mui/material/Grid';
import { GET_ALL_TAXES, GET_TAX_BY_ID, UPDATE_TAX, CREATE_TAX } from '../../../gql/taxes';
import Button from '@mui/material/Button';
import useSnackbar from '../../../hooks/useSnackbar';
import { getTenantId } from '../../../services/tenancy';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const TaxForm = () => {
  const { id } = useParams();
  const isCreate = id === 'create';
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [mutateTax] = useMutation(isCreate ? CREATE_TAX : UPDATE_TAX, {
    refetchQueries: [{ query: GET_ALL_TAXES }],
  });
  const [getCurrentTax, { data }] = useLazyQuery(GET_TAX_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  const navigateBack = () => navigate('/dashboard/invoices');

  const [displayEditModal, setDisplayEditModal] = React.useState(false);
  const handleCloseModal = () => {
    setDisplayEditModal(false);
  };

  const handleOpenModal = () => {
    setDisplayEditModal(true);
  };

  const handleSubmit = () => {
    snackbar.setGlobalLoading(true);
    const { values } = formik;
    snackbar.sendAlert({ level: 'info', message: 'Salvando Dados...' });

    const input: Record<string, any> = { tenantId: getTenantId() };
    Object.keys(validationSchema.fields).map((key) => {
      if (Object.hasOwn(values, key)) input[key] = values[key];
      return null;
    });
    mutateTax({ variables: { input } })
      .then(() =>
        snackbar.sendAlert({ level: 'success', message: 'Dados salvos com sucesso ' })
      )
      .catch(() => {
        snackbar.sendAlert({ level: 'error', message: 'Erro Salvando dados' });
      })
      .finally(() => {
        snackbar.setGlobalLoading(false);
        navigateBack();
      });
  };

  const formik = useFormik({
    initialValues: data?.getTaxById
      ? {
          ...data.getTaxById,
          taxId: data.getTaxById?.tax?.id,
        }
      : {},
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!isCreate) getCurrentTax({ variables: { id } });
  }, [isCreate]);

  return (
    <>
      <Header title={`${isCreate ? 'Criar' : 'Editar'} Imposto`} />
      <Paper sx={{ width: '100%', mb: 2, p: 2 }}>
        <form onSubmit={formik.handleSubmit}>
          <Dialog
            open={displayEditModal}
            onClose={handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Editar Imposto</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Você tem certeza que gostaria de salvar essa edição?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid>
                <Button onClick={handleCloseModal}>Cancelar</Button>
                <Button type="submit" onClick={handleSubmit}>
                  Salvar
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
          <Grid container spacing={4}>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  autoFocus
                  variant="standard"
                  name="description"
                  label="Descrição do Imposto"
                  value={formik.values.description ?? ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.description)}
                  helperText={formik.errors.description}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="typel">Tipo do Imposto</InputLabel>
                  <Select
                    labelId="type"
                    label="Tipo do Imposto"
                    name="type"
                    variant="standard"
                    inputProps={{
                      name: 'type',
                    }}
                    value={formik.values.type ?? ''}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.type)}
                  >
                    <MenuItem value="fixed">Fixo</MenuItem>
                    <MenuItem value="percentage">Por Porcentagem</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  autoFocus
                  type="number"
                  variant="standard"
                  name="value"
                  label="Valor Sugerido"
                  value={formik.values.value ?? ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.value)}
                  helperText={formik.errors.value}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12} justifyContent="flex-end">
              <Grid item>
                <Button size="large" onClick={navigateBack}>
                  Cancelar
                </Button>
              </Grid>
              {!isCreate ? (
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    type="button"
                    onClick={handleOpenModal}
                  >
                    Salvar
                  </Button>
                </Grid>
              ) : (
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Salvar
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default TaxForm;
