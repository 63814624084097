import { Role } from './roles';

const requiredField = (fieldName: string) => `${fieldName} é um campo obrigatorio`;
const errorField = (fieldName: string) => `Erro: ${fieldName}`;
const minField = (min: number, fieldName: string) => `${fieldName} deve ter pelo menos ${min} caracteres`;

export const ufList = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO'

];

export enum invoiceStatus {
  DRAFT,
  CREATED,
  PAID,
  OVERDUE,
  CANCELLED,
  DISPUTE,
  REFUND,
}

const invoiceStatusMap = (Object.keys(invoiceStatus) as Array<keyof typeof invoiceStatus>).filter(el => Number.isNaN(Number(el)));

const userRolesMap = (Object.keys(Role) as Array<keyof typeof Role>).filter(el => Number.isNaN(Number(el)));


export {
  userRolesMap,
  invoiceStatusMap,
  requiredField,
  errorField,
  minField
};
