import React from 'react';
import Header from '../../components/header';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CallMadeIcon from '@mui/icons-material/CallMade';
import TableWithoutRows from '../../components/table';

function Dashboard() {
  return (
    <Grid item>
      <Header title="Dashboards" />
      <Typography>Análise Geral</Typography>
      <Grid container md={12} justifyContent="space-between">
        <Grid item md={2.5}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {/* trocar para componente */}
            <Paper
              sx={{
                height: 150,
                width: 550,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                style={{
                  marginLeft: 'calc(100% - 40px)',
                  textAlign: 'center',
                }}
              >
                <CallMadeIcon fontSize="small" color="primary" />
              </Typography>
              <Typography justifyContent="center" variant="h4" color="primary">
                2000
              </Typography>
              <Typography variant="h6">Contratos Fechados</Typography>
              <Typography variant="subtitle2">12% a mais que semana passada</Typography>
            </Paper>
          </Box>
        </Grid>

        <Grid item md={2.5}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Paper
              sx={{
                height: 150,
                width: 550,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                style={{
                  marginLeft: 'calc(100% - 40px)',
                  textAlign: 'center',
                }}
              >
                <CallMadeIcon fontSize="small" color="primary" />
              </Typography>
              <Typography justifyContent="center" variant="h4" color="primary">
                300
              </Typography>
              <Typography variant="h6">Chamados Atendidos</Typography>
              <Typography variant="subtitle2">12% a mais que semana passada</Typography>
            </Paper>
          </Box>
        </Grid>

        <Grid item md={2.5}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Paper
              sx={{
                height: 150,
                width: 550,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                style={{
                  marginLeft: 'calc(100% - 40px)',
                  textAlign: 'center',
                }}
              >
                <CallMadeIcon fontSize="small" color="primary" />
              </Typography>
              <Typography justifyContent="center" variant="h4" color="primary">
                $27.200
              </Typography>
              <Typography variant="h6">Pedidos de Venda</Typography>
              <Typography variant="subtitle2">12% a mais que semana passada</Typography>
            </Paper>
          </Box>
        </Grid>

        <Grid item md={2.5}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Paper
              sx={{
                height: 150,
                width: 550,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                style={{
                  marginLeft: 'calc(100% - 40px)',
                  textAlign: 'center',
                }}
              >
                <CallMadeIcon fontSize="small" color="primary" />
              </Typography>
              <Typography justifyContent="center" variant="h4" color="primary">
                $280.000
              </Typography>
              <Typography variant="h6">Lucro Obtido</Typography>
              <Typography variant="subtitle2">12% a mais que semana passada</Typography>
            </Paper>
          </Box>
        </Grid>
        <Grid item md={12} display="flex" justifyContent="space-between">
          <Grid item md={5.8} mt={10}>
            <TableWithoutRows />
          </Grid>
          <Grid item md={5.8} mt={10}>
            <Paper>
              <Box height={415} width={100} bgcolor="white" />
            </Paper>
          </Grid>
        </Grid>

        <Grid item md={12} display="flex" justifyContent="space-between">
          <Grid item md={2.8} mt={10}>
            <Paper>
              <Box height={415} bgcolor="white" />
            </Paper>
          </Grid>
          <Grid item md={2.8} mt={10}>
            <Paper>
              <Box height={415} bgcolor="white" />
            </Paper>
          </Grid>
          <Grid item md={2.8} mt={10}>
            <Paper>
              <Box height={415} bgcolor="white" />
            </Paper>
          </Grid>
          <Grid item md={2.8} mt={10}>
            <Paper>
              <Box height={415} bgcolor="white" />
            </Paper>
          </Grid>
        </Grid>
        <Grid item md={12} display="flex" justifyContent="space-between">
          <Grid item md={5.8} mt={10}>
            <Paper>
              <Box height={415} width={100} bgcolor="white" />
            </Paper>
          </Grid>
          <Grid item md={5.8} mt={10}>
            <Paper>
              <Box height={415} width={100} bgcolor="white" />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Dashboard;
