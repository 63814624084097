import * as React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../header';
import { Paper } from '@mui/material';

interface FormViewProps {
  entity: string;
  width: string;
  form: any;
}
const FormView: React.FC<FormViewProps> = ({ entity, width, form }) => {
  const { id } = useParams();
  const isCreate = id === 'create';

  return (
    <>
      <Header title={`${isCreate ? 'Criar' : 'Editar'} ${entity}`} />
      <Paper
        sx={{
          width,
          m: 'auto',
          p: 2,
          Color: '#000000',
        }}
      >
        {form}
      </Paper>
    </>
  );
};
export default FormView;
