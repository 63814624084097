import { gql } from '@apollo/client';

export const GET_PAGINATED_PARTNERS = gql`
  query getPaginatedPartners($pageInfo: PageInfo!, $orderBy: OrderByFilter, $search: SearchFilter) {
    getPaginatedPartners(pageInfo: $pageInfo, orderBy: $orderBy, search: $search) {
      items {
        id
        companyName
        tradingName
        contactEmail
        contactName
        contactNumber
        email
        cnpj
        website
        street
        district
        city
        zipcode
        telephone
        state
        contracts {
          id
          product_tier
          product {
            name
          }
        }
      }
      meta {
        itemsPerPage
        totalItems
        currentPage
        totalPages
      }
    }
  }
`;

export const GET_ALL_PARTNERS = gql`
  query getAllPartners {
    getAllPartners {
      id
      companyName
      tradingName
      contactEmail
      contactName
      contactNumber
      email
      cnpj
      website
      street
      district
      city
      zipcode
      telephone
      state
      contracts {
        id
        product_tier
        product {
          name
        }
      }
    }
  }
`;

export const GET_PARTNER_BY_ID = gql`
  query getPartnerById($id: String!) {
    getPartnerById(id: $id) {
      id
      companyName
      tradingName
      contactEmail
      contactName
      contactNumber
      email
      cnpj
      website
      street
      number
      complement
      district
      city
      zipcode
      telephone
      state
      users {
        id
        firstName
        lastName
        role
        email
      }
      contracts {
        id
        product_tier
        seller{
          id
          firstName
          lastName
        }
        product {
          name
          tiers {
            tier
            mmc
          }
        }
      }
      invoices {
        id
        saleId
        dueDates
        totalValue
        startDate
        endDate
      }
    }
  }
`;


export const CREATE_PARTNER = gql`
  mutation createPartner ($input: [CreatePartnerInput!]!) {
    createPartner (input: $input) {
      street
      number
      complement
      city
      cnpj
      id
      isActive
      companyName
      tradingName
      state
      telephone
      tenantId
      website
      zipcode
    }
  }
`;

export const UPDATE_PARTNER = gql`
  mutation updatePartner ($input: UpdatePartnerInput!) {
    updatePartner (input: $input) {
      id
    }
  }
`;

export const REMOVE_PARTNERS = gql`
  mutation removePartner ($id: String!) {
    removePartner (id: $id)
  }
`;
