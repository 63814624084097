import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

export const Alerts = styled(Alert)`
  width: 400px;
  height: 60px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TypographyDetails = styled(Typography)`
  background: #2d4490;
  font-size: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 15px 10px;
`;

export const TypographyAlerts = styled(Typography)`
  margin-top: 15px;
  border-bottom: 1px solid #d5d5d5;
  padding-left: 10px;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const TypographyDetailAlerts = styled(Typography)`
  font-weight: bold;
`;

export const GridMenu = styled(Grid)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid black;
  width: 100%;
`;
