import React from 'react';
import Paper from '@mui/material/Paper';
import { useMutation, useQuery } from '@apollo/client';
import GenericTable from '../../components/genericTable';
import CreateEntity from '../../components/createEntity';
import Header from '../../components/header';
import { useNavigate } from 'react-router-dom';
import { DELETE_POST, GET_ALL_POSTS } from '../../gql/posts';

const columnsOptions = {
  id: {
    hide: true
  }
};

const PostsRoute = () => {
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_ALL_POSTS);
  const [deletePost] = useMutation(DELETE_POST, { refetchQueries: [{ query: GET_ALL_POSTS }] });

  const handleNewPost = () => navigate('create');
  const handleEditPost = ({ id = '' }: { id?: string }) => navigate(id);
  const handleDeletePost = ({ id = '' }) => deletePost({ variables: { id } });

  return (
    <>
      <Header title="Posts" />
      <Paper sx={{ width: '100%', mb: 2 }}>
        <GenericTable data={data?.getAllPosts} loading={loading} columnOptions={columnsOptions} onEdit={handleEditPost} onDelete={handleDeletePost} />
        <CreateEntity onClick={handleNewPost} />
      </Paper>
    </>
  );
};

export default PostsRoute;
