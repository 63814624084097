import React from 'react';
import Paper from '@mui/material/Paper';
import { useMutation, useQuery } from '@apollo/client';
import GenericTable from '../../components/genericTable';
import { useNavigate } from 'react-router-dom';
import { DELETE_CONTRACT, GET_ALL_CONTRACTS } from '../../gql/contracts';
import Assignment from '@mui/icons-material/Assignment';
import ActionButton from '../../components/actionButton';
import usePermissions from '../../hooks/usePermissions';

const columnsOptions = {
  id: {
    hide: true,
  },
  product_tier: {
    headerName: 'Tier',
  },
  partner: {
    headerName: 'Parceiro',
    valueGetter: ({ value }: Record<any, any>) => value.tradingName,
  },
  product: {
    headerName: 'Produto',
    valueGetter: ({ value }: Record<any, any>) => value.name,
  },
};

const ContractsRoute = () => {
  const navigate = useNavigate();
  const { isAdmin } = usePermissions();

  const { data, loading } = useQuery(GET_ALL_CONTRACTS);
  const [deleteContract] = useMutation(DELETE_CONTRACT, {
    refetchQueries: [{ query: GET_ALL_CONTRACTS }],
  });

  const handleNewContract = () => navigate('/dashboard/contracts/create');
  const handleEditContract = ({ id = '' }: { id?: string }) =>
    navigate(`/dashboard/contracts/${id}`);
  const handleDeleteContract = ({ id = '' }) => deleteContract({ variables: { id } });

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <GenericTable
        data={data?.getAllContracts}
        loading={loading}
        columnOptions={columnsOptions}
        onEdit={handleEditContract}
        onDelete={handleDeleteContract}
        hideDeleteButton={!isAdmin}
      />
      {isAdmin ? (
        <ActionButton
          title="Criar novo contrato"
          typography={<Assignment fontSize="medium" />}
          onClick={handleNewContract}
        />
      ) : null}
    </Paper>
  );
};

export default ContractsRoute;
