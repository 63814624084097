import React from 'react';
import Header from '../../../components/header';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TableDescription from '../../../components/tableDescription';
import TableUsers from '../../../components/tableUsers';

const EditGroups = () => (
  <Grid container item>
    <Header title="Editar Grupo" />
    <Grid container item>
      <Grid container item spacing={10} sx={{ mb: 2 }} justifyContent="space-between">
        <Grid item xs={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Nome Do Grupo</InputLabel>
            <Input value="Comercial Admin" name="comercialAdmin" />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Descrição Do Grupo</InputLabel>
            <Input value="Usuário Administrador Comercial" name="adminComercial" />
          </FormControl>
        </Grid>
      </Grid>
      <TableUsers />
      <TableDescription />
    </Grid>
  </Grid>
);

export default EditGroups;
