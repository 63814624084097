import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import { lighten, darken } from 'polished';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

interface TenantTheme {
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor?: string;
}

const defaultTheme: TenantTheme = {
  primaryColor: '#2d4490',
  secondaryColor: '#388cba',
};


const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>((props, ref) => {
  const { href, ...other } = props;
  // @ts-ignore
  return <RouterLink {...other} ref={ref} to={href} />;
});

// Theme should be generated based on the client's theme from BE;
const themeCreator = (tenantTheme = defaultTheme) => createTheme({
  palette: {
    primary: {
      light: lighten(0.2, tenantTheme.primaryColor),
      main: tenantTheme.primaryColor,
      dark: darken(0.2, tenantTheme.primaryColor),
      contrastText: '#fff'
    },
    secondary: {
      light: lighten(0.2, tenantTheme.secondaryColor),
      main: tenantTheme.secondaryColor,
      dark: darken(0.2, tenantTheme.secondaryColor),
      contrastText: '#fff'
    }
  },
  components: {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#DDD'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: '#DDD'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#DDD'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        // @ts-ignore
        component: LinkBehavior
      }
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    }
  }
});

export default themeCreator;
