import * as yup from 'yup';

const productSchema = yup.object({
  id: yup
    .string()
    .label('id'),
  manufacturerId: yup.string().required(),
  name: yup.string().required(),
  tiers: yup.array(),
  resources: yup.array(),
});

export default productSchema;
