import { gql } from '@apollo/client';

export const GET_ALL_CONTRACTS_RELATIONS = gql`
  query getAllContractsRelations{
    getAllContractsRelations {
      id
      product_tier
      partner{
        id
        tradingName
      }
      product{
        id
        manufacturerId
        name
        resources{
          id
          sku
          name
          productId
          prices {
            unitCostPrice
            unitSalePrice
            unit
          }
        }
      }
    }
  }`;
export const GET_ALL_CONTRACTS = gql`
  query getAllContracts{
    getAllContracts {
      id
      product_tier
      partner{
        id
        tradingName
      }
      product{
        id
        name
      }
    }
  }`;

export const GET_CONTRACT_BY_ID = gql`
  query getContractById($id: String!){
    getContractById(id: $id) {
      id
      product_tier
      partnerId
      dueDates,
      dollarType,
      dollarValue,
      paymentType,
      freeUntil,
      mmcFreeUntil,
      credit {
        active,
        creditType,
        value,
        valueModel,
        remainingMonths,
        issuedDays,
        description
        currency
        currencyType
        currencyValue
      }
      taxes {
        id
        name,
        description,
        type,
        value
      },
      sellerId,
      productId
    }
  }`;

export const CREATE_CONTRACT = gql`
  mutation createContract($input: CreateContractInput!){
    createContract (input: $input) {
      id
      product_tier
      partnerId
      productId
      dueDates
      taxes {
        name,
        description,
        type,
        value
      },
      sellerId
    }
  }`;

export const UPDATE_CONTRACT = gql`
  mutation updateContract($input: UpdateContractInput!){
    updateContract (input: $input) {
      id
    }
  }`;



export const DELETE_CONTRACT = gql`
  mutation removeContract ($id: String!) {
    removeContract (id: $id)
  }`;
