import * as React from 'react';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
  DataGrid,
  GridActionsCellItem,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { ReactElement } from 'react';
import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { GridCallbackDetails } from '@mui/x-data-grid/models/api';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

interface GenericTableProps {
  data: Record<any, any>[];
  dense?: boolean;
  toolbar?: {
    [key: string]: boolean;
  };
  loading?: boolean;
  columnOptions?: Record<any, any>;
  onEdit?: (dataRow: Record<any, any>) => void;
  onDelete?: (dataRow: Record<any, any>) => void;
  customOperations?: any[];
  hideDeleteButton?: boolean;
  filterMode?: 'client' | 'server';
  onFilter?: (model: GridFilterModel, details: GridCallbackDetails<'filter'>) => void;
  onSort?: (model: GridSortModel, details: GridCallbackDetails) => void;
  searchInToolbar?: any;
  onPaginate?: (pageInfo: { limit: number; page: number }) => void;
  pageInfo?: {
    totalItems: number;
    currentPage: number;
    itemsPerPage: number;
  };
  hide?: boolean
}

const SearchToolBar = () => (
  <Box>
    <GridToolbarQuickFilter
      quickFilterParser={(searchInput: string) =>
        searchInput
          .split(',')
          .map((value) => value.trim())
          .filter((value) => value !== '')
      }
    />
  </Box>
);

const GenericTable = ({
  data = [],
  columnOptions = {},
  loading,
  onEdit,
  onDelete,
  hideDeleteButton,
  hide,
  customOperations,
  toolbar = {
    search: true,
    columns: true,
    filter: true,
    density: false,
    expt: true,
  },
  dense = false,
  filterMode = 'client',
  onFilter,
  onSort,
  searchInToolbar,
  onPaginate,
  pageInfo,
}: GenericTableProps) => {
  const [displayDeleteModal, setDisplayDeleteModal] = React.useState(false);
  const [currentEntity, setCurrentEntity] = React.useState<Record<any, any> | undefined>(
    undefined
  );

  const [sortModel] = React.useState<GridSortModel>([]);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 1,
    pageSize: 30,
  });

  const handlePagination = (newPage: GridPaginationModel) => {
    onPaginate?.({
      page: newPage.page,
      limit: newPage.pageSize,
    });
    setPaginationModel(newPage);
  };

  const [rowCountState, setRowCountState] = React.useState(pageInfo?.totalItems || 0);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.totalItems !== undefined ? pageInfo?.totalItems : prevRowCountState
    );
  }, [pageInfo?.totalItems, setRowCountState]);

  const [localHiddenFields, setLocalHiddenFields] =
    React.useState<GridColumnVisibilityModel>({});

  const columns = React.useMemo(() => {
    if (data && data.length > 0) {
      return [
        ...Object.keys(data[0]).map((field) => ({
          field,
          flex: 1,
          ...(columnOptions?.[field] ?? {}),
        })),
        {
          field: 'ações',
          type: 'actions',
          getActions: (params: GridRowParams) =>
            customOperations?.map((c) => (
              <GridActionsCellItem
                icon={c.icon as ReactElement}
                onClick={() => {
                  c.onClick(params);
                }}
                showInMenu
                label={c.label as string}
              />
            )) ?? [
              <GridActionsCellItem
                icon={<RemoveRedEyeIcon />}
                onClick={handleEditButton(params)}
                label="Editar"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                onClick={handleDeleteButton(params)}
                label="Editar"
                hidden={hide}
                disabled={hideDeleteButton}
              />,
            ],
        },
      ];
    }
    return [];
  }, [data, hideDeleteButton, hide]);

  const hiddenColumns = React.useMemo(() => {
    const hiddenFields: GridColumnVisibilityModel = {};

    columns.map((item) => {
      if (item?.hide) hiddenFields[item.field] = false;
      return null;
    });
    return {
      ...hiddenFields,
      ...localHiddenFields,
    };
  }, [columns, localHiddenFields]);

  const handleCloseModal = () => {
    setCurrentEntity(undefined);
    setDisplayDeleteModal(false);
  };

  const customToolbar = () => (
    <GridToolbarContainer>
      {toolbar.search && <SearchToolBar />}
      {searchInToolbar}
      {toolbar.columns && <GridToolbarColumnsButton />}
      {toolbar.filter && <GridToolbarFilterButton />}
      {toolbar.density && <GridToolbarDensitySelector />}
      {toolbar.expt && <GridToolbarExport />}
    </GridToolbarContainer>
  );
  const handleEdit = ({ row }: { row: Record<any, any> }) => onEdit?.(row);
  const handleDelete = () => {
    if (currentEntity) onDelete?.(currentEntity);
    handleCloseModal();
  };

  const handleDeleteButton = (currentRow: Record<any, any>) => () => {
    setCurrentEntity(currentRow);
    setDisplayDeleteModal(true);
  };

  const handleEditButton = (currentRow: Record<any, any>) => () =>
    handleEdit({ row: currentRow });

  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: 2,
      }}
    >
      <Dialog
        open={displayDeleteModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Deletar Entidade</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem certeza que você quer deletar esta entidade?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button onClick={handleDelete} autoFocus>
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
      <DataGrid
        autoHeight
        disableRowSelectionOnClick
        checkboxSelection={false}
        density={!dense ? 'standard' : 'compact'}
        filterMode={filterMode}
        sortingMode={filterMode}
        paginationMode={filterMode}
        localeText={{
          columnsPanelTextFieldLabel: 'Encontrar Coluna',
          columnsPanelTextFieldPlaceholder: 'Título da Coluna',
          columnsPanelShowAllButton: 'Mostrar Todas',
          columnsPanelHideAllButton: 'Ocultar Todas',
          filterPanelInputLabel: 'Valor',
          filterPanelColumns: 'Colunas',
          filterOperatorStartsWith: 'Inicia Com',
          filterOperatorEndsWith: 'Termina com',
          filterOperatorIsEmpty: 'Vazio',
          filterOperatorIsNotEmpty: 'Não Vazio',
          filterOperatorContains: 'Contém',
          filterOperatorEquals: 'igual',
          toolbarExportCSV: 'Baixar em CSV',
          toolbarExportPrint: 'Imprimir',

          columnMenuUnsort: 'Filtragem',
          filterPanelInputPlaceholder: 'Filtragem...',
          filterOperatorIsAnyOf: 'Qualquer um de...',
          columnMenuSortAsc: 'Organizar por Crescente',
          columnMenuSortDesc: 'Organizar por Decrescente',
          columnMenuFilter: 'Filtro',
          columnMenuShowColumns: 'Mostrar Colunas',
          columnMenuHideColumn: 'Ocultar Colunas',

          noRowsLabel: 'Nada encontrado...',
          toolbarQuickFilterPlaceholder: 'Procurar…',
          toolbarColumns: 'Colunas',
          toolbarFilters: 'Filtro',
          toolbarDensity: 'Densidade',
          toolbarExport: 'Exportar',
        }}
        onColumnVisibilityModelChange={setLocalHiddenFields}
        columnVisibilityModel={hiddenColumns}
        sx={{
          padding: '2em',
          borderWidth: 1,
          borderStyle: 'solid',
        }}
        loading={loading}
        rows={data}
        columns={columns}
        pageSizeOptions={[10, 30, 50]}
        initialState={{
          pagination: { paginationModel: { pageSize: 30 } },
          sorting: {
            sortModel,
          },
          filter: {
            filterModel: {
              items: [],
            },
          },
        }}
        slots={{
          toolbar: customToolbar,
        }}
        {...(filterMode === 'server'
          ? {
              onFilterModelChange: onFilter,
              onSortModelChange: onSort,
              paginationModel,
              onPaginationModelChange: handlePagination,
              rowCount: rowCountState,
            }
          : {})}
      />
    </Box>
  );
};

export default GenericTable;
