import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import TextField from '@mui/material/TextField';

import { CREATE_DRAFT_INVOICE, GET_ALL_INVOICES } from '../../../gql/invoices';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { GET_ALL_PARTNERS } from '../../../gql/partners';
import Loading from '../../../components/loading';
import { InputLabel, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';


const CreateInvoiceDraft = ({ viewModal, onClose }: any) => {
  const navigate = useNavigate();
  const [invoiceDraft, setInvoiceDraft] = useState<Record<any, any>>({
  });
  const [contracts, setContracts] = useState();
  const [mutateInvoice] = useMutation(CREATE_DRAFT_INVOICE, { refetchQueries: [{ query: GET_ALL_INVOICES }] });
  const { data: partnersData, loading: loadingPartners } = useQuery(GET_ALL_PARTNERS);
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(2022);

  const [loading, setLoading] = useState(false);
  const handlePartnerChange = (_: unknown, value: Record<string, any>) => {
    const { contracts } = partnersData.getAllPartners.find((p: { id: string; }) => p.id === value.id);
    console.log(value, contracts);
    setContracts(contracts);
    setInvoiceDraft({
      partnerId: value.id
    });
  };
  const handleChange = (_: unknown, value: Record<string, any>) => {
    setInvoiceDraft({
      contractId: value.id,
      partnerId: invoiceDraft.partnerId
    });
    console.log(value, invoiceDraft);
  };

  const closeModal = () => onClose(false);
  const sortPartner = (partners:any) => partners.slice().sort(compare).map((item: Record<any, any>) => (item));
  const compare = (a:any, b:any) => {
    if (a.tradingName < b.tradingName) {
      return -1;
    }
    if (a.tradingName > b.tradingName) {
      return 1;
    }
    return 0;
  };
  const handleSubmit = () => {
    setLoading(true);
    mutateInvoice({ variables: { input: { ...invoiceDraft, month, year } } })
      .then(result => {
        console.log(result.data.createDraftInvoice.id);
        setLoading(false);
        onClose(false);
        navigate(result.data.createDraftInvoice.id, { state: result.data.createDraftInvoice });
      });
  };

  const canSave = useMemo(() => {
    if (invoiceDraft.contractId
      && invoiceDraft.partnerId) {
      return false;
    }
    return true;
  }, [invoiceDraft]);

  return (
    <Dialog open={viewModal} onClose={closeModal}>
      <DialogTitle>Criar Draft</DialogTitle>
      <DialogContent>
        <Grid container item xs={12}>
          <Grid item xs={12} my={2}>
            <Autocomplete
              onChange={handlePartnerChange}
              getOptionLabel={(option: any) => `${option?.tradingName}`}
              loading={loadingPartners}
              options={partnersData ? sortPartner(partnersData.getAllPartners) : []}
              sx={{ width: 300 }}
              renderInput={(params: any) => <TextField {...params} label="Parceiro" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={handleChange}
              getOptionLabel={(option: any) => `${option?.product.name} - Tier: ${option.product_tier}`}
              disabled={!(contracts)}
              options={contracts ?? []}
              sx={{ width: 300 }}
              renderInput={(params: any) => <TextField {...params} label="Contrato" />}
            />
          </Grid>
          <Grid item xs={2} mr={5}>
            <InputLabel id="month-label">Mes</InputLabel>
            <Select
              labelId="month-label"
              id="month"
              fullWidth
              value={month}
              label="Mes"
              onChange={e => setMonth(Number(e.target.value))}
            >
              <MenuItem value={1}>Janeiro</MenuItem>
              <MenuItem value={2}>Fevereiro</MenuItem>
              <MenuItem value={3}>Março</MenuItem>
              <MenuItem value={4}>Abril</MenuItem>
              <MenuItem value={5}>Maio</MenuItem>
              <MenuItem value={6}>Junho</MenuItem>
              <MenuItem value={7}>Julho</MenuItem>
              <MenuItem value={8}>Agosto</MenuItem>
              <MenuItem value={9}>Setembro</MenuItem>
              <MenuItem value={10}>Outubro</MenuItem>
              <MenuItem value={11}>Novembro</MenuItem>
              <MenuItem value={12}>Dezembro</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={2}>
            <InputLabel id="month-label">Ano</InputLabel>
            <TextField
              fullWidth
              autoFocus
              variant="outlined"
              type="number"
              name="year"
              label="Ano"
              InputProps={{ inputProps: { min: 2000, max: 2022 } }}
              value={!year ? 2022 : year}
              onChange={e => { setYear(Number(e.target.value)); }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Cancelar</Button>
        <Button onClick={handleSubmit} disabled={canSave}>Gerar</Button>
      </DialogActions>
      {loading && (<Loading open={loading} />)}
    </Dialog>
  );
};

export default CreateInvoiceDraft;
