import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const LoadingWrapper = styled.div`
  text-align: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(255, 255, 255, 0.7);
  z-index: 99;
  top: 0;
  left: 0;
`;

export const LoaderBox = styled(Box)`
  text-align: center;
`;

export const CircularLoader = styled(CircularProgress)`
  margin: 0 auto;
  margin-top: 30%;
`;
