import * as yup from 'yup';

const schema = yup.object({
  id: yup.string().label('id'),
  partnerId: yup.string().required(),
  productId: yup.string().required(),
  product_tier: yup.number().required(),
  sellerId: yup.string().required(),
  dollarType: yup.string().required(),
  dollarValue: yup.number().when(['dollarType'], {
    is: (val: string) => val === 'PTAX',
    then: () => yup.number().notRequired(),
    otherwise: () => yup.number().required().min(0.1).max(100),
  }),
  taxes: yup.array().required(),
  dueDates: yup.array().required(),
  paymentType: yup.string().required().default('BOLETO ITAÚ'),
  credit: yup.array().notRequired(),
  freeUntil: yup.string().notRequired().nullable(),
  mmcFreeUntil: yup.string().notRequired().nullable(),
});

export default schema;
