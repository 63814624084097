import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_CRONS = gql`
  query getAllCron {
    getAllCron {
        date
        description
        id
        status
    }
}
`;
