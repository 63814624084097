import { gql } from '@apollo/client';

const GET_PARTNER_INVOICE_BY_ID = gql`
  query getPartnerInvoice($input: GetPartnerInvoiceInput!) {
    getPartnerInvoice (input: $input) {
      saleId
      startDate
      status
      endDate
      totalValue
      dueDates
      paymentType
      formattedTier
      discounts
      subTotal
      id
        data {
            client {
                cnpj
                companyName
                tradingName
            }
            companyAddress {
                street
                number
                complement
                district
                zipcode
                city
                state
            }
            contact {
                contactEmail
                contactName
                contactNumber
                telephone
            }
            credits {
                active
                creditType
                currency
                currencyType
                currencyValue
                description
                issuedDays
                remainingMonths
                value:
                valueModel
            }
            dollarRate
            dollarType
            observations {
                id
                message
                type
            }
            productTier
            regions {
                items {
                    item
                    qtd
                    sku
                    total
                    unitPrice
                }
                namespace
                wallet
            }
            taxes {
                description
                id
                name
                type
                value
            }
        }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { GET_PARTNER_INVOICE_BY_ID };
