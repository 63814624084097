import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export const GridContainerCenter = styled(Grid)`
  justify-content: center;
  align-items: center;
`;

export const LoginBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
