import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ALERTS_BY_ID } from '../../../gql/alerts';
import Grid from '@mui/material/Grid';
import { TypographyDetails, TypographyAlerts, TypographyDetailAlerts } from '../style';
import Typography from '@mui/material/Typography';
import Header from '../../../components/header';
import RedCircle from '../../../assets/images/redCircle.svg';
import LoadingScreen from '../../../components/loadingScreen';

const AlertsForm = () => {
  const { id } = useParams<{ id: string }>();

  const { loading, error, data } = useQuery(GET_ALERTS_BY_ID, {
    variables: { id },
  });

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <Typography>Ocorreu um erro ao buscar os dados do alerta.</Typography>;
  }

  const selectedAlert = data.getAlertById;

  if (!selectedAlert) {
    return <Typography>Alerta não encontrado</Typography>;
  }

  return (
    <>
      <Header title="Detalhes sobre o alerta" />
      <Grid sx={{ border: '1px solid #D5D5D5' }}>
        <TypographyDetails variant="h6">Detalhes</TypographyDetails>
        <TypographyAlerts>
          <TypographyDetailAlerts> ID:</TypographyDetailAlerts>
          {selectedAlert.id}
        </TypographyAlerts>

        <TypographyAlerts>
          <TypographyDetailAlerts>Level: </TypographyDetailAlerts>
          <Grid sx={{ display: 'flex', alignItens: 'center' }}>
            {selectedAlert.level === 'ERROR' && (
              <img src={RedCircle} alt="" style={{ marginRight: '10px' }} />
            )}
            {selectedAlert.level}
          </Grid>
        </TypographyAlerts>

        <TypographyAlerts>
          <TypographyDetailAlerts>Status:</TypographyDetailAlerts>
          {selectedAlert.status}
        </TypographyAlerts>

        <TypographyAlerts>
          <TypographyDetailAlerts>Descrição:</TypographyDetailAlerts>
          {selectedAlert.description}
        </TypographyAlerts>

        <TypographyAlerts>
          <TypographyDetailAlerts>Count:</TypographyDetailAlerts>
          {selectedAlert.count}
        </TypographyAlerts>

        <TypographyAlerts>
          <TypographyDetailAlerts>TenantId:</TypographyDetailAlerts>
          {selectedAlert.tenantId}
        </TypographyAlerts>

        <TypographyAlerts>
          <TypographyDetailAlerts>Hash:</TypographyDetailAlerts>
          {selectedAlert.hash}
        </TypographyAlerts>

        <TypographyAlerts>
          <TypographyDetailAlerts>Última ocorrência :</TypographyDetailAlerts>
          {selectedAlert.lastOccurrence}
        </TypographyAlerts>
      </Grid>
    </>
  );
};

export default AlertsForm;
