import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { requiredField } from '../../../utils/forms';
import { RESET_PASSWORD } from '../../../gql/users';

interface forgotPasswordProps {
  toggleReset: () => void
}

const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .email()
    .required(requiredField('Email'))
});

const ForgotPasswordForm = ({ toggleReset }: forgotPasswordProps) => {
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [reset] = useMutation(RESET_PASSWORD);

  const handleSubmit = (values: Record<any, any>) => {
    const input: Record<any, any> = { ...values };

    reset({ variables: { input } })
      .then(() => setShowFeedback(!showFeedback))
      .catch(er => console.error(er));
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {showFeedback
        ? (
          <>
            <div>
              Caso você tenha uma conta cadastrado conosco, você irá receber um email com instruções para resetar sua senha.
            </div>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              onClick={toggleReset}
              sx={{ mt: 2, mb: 2 }}
            >
              Voltar para assinar
            </Button>
          </>
        )
        : (
          <>
            <TextField
              autoFocus
              margin="normal"
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email ?? ''}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <Button
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              sx={{ mt: 2, mb: 2 }}
            >
              Alterar senha
            </Button>
          </>
        )}
    </form>
  );
};

export default ForgotPasswordForm;
