import React, { useState } from 'react';
import LoginForm from './forms/loginForm';
import ForgotPasswordForm from './forms/forgotPasswordForm';
import {
  LoginContainer,
  LoginBox,
  LoginPaper,
} from './styles';

const Login = () => {
  const [showReset, setShowReset] = useState<boolean>(false);

  const toggleReset = () => setShowReset(!showReset);

  return (
    <LoginContainer maxWidth="xl">
      <LoginBox>
        <LoginPaper elevation={12}>
          {showReset
            ? <ForgotPasswordForm toggleReset={toggleReset} />
            : <LoginForm toggleReset={toggleReset} />}
        </LoginPaper>
      </LoginBox>
    </LoginContainer>
  );
};

export default Login;
