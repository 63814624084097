import React, { useEffect, useMemo } from 'react';
import { Route, Routes, useLocation, useNavigate, matchPath } from 'react-router-dom';
import Login from '../login';
import NotFound from '../notFound';
import Dashboard from '../dashboard';
import { ThemeProvider } from '@mui/material/styles';
import themeCreator from '../../theme/themeCreator';
import useAuthToken from '../../hooks/useAuthToken';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import useSnackbar from '../../hooks/useSnackbar';
import UsersRoute from '../users';
import UserForm from '../users/form';
import PartnerForm from '../partners/form';
import PostsRoute from '../posts';
import PostForm from '../posts/form';
import ProductForm from '../products/form';
import ManufacturerForm from '../manufacturers/form';
import TaxForm from '../taxes/form';
import WalletForm from '../wallet/form';
import Profile from '../profile';
import InvoicesRoute from '../invoices';
import InvoiceForm from '../invoices/form';
import ContractForm from '../contracts/form';
import PasswordReset from '../passwordReset';
import FormView from '../../components/form';
import Calendar from '../calendar';
import Home from '../home';
import EditGroups from '../group/editGroup/index';
import Assessments from '../assessments';
import CreateAssessment from '../assessments/form';
import EditAssissments from '../assessments/edit';
import DashboardsScreen from '../dashboardsScreen';
import Alerts from '../alerts';
import AlertsForm from '../alerts/form';
import InvoiceReader from '../invoice-reader';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const publicPaths = ['/partner/:partnerId/invoice/:invoiceId', '/passwordReset'];

const App = () => {
  const [token, , , isTokenValid] = useAuthToken();
  const { hideAlert, displayAlert, currentAlert } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useMemo(() => {
    // Theme should be generated based on the client's theme from BE;
    // in the future
    console.info('No external theme found, applying default theme');
    return themeCreator();
  }, []);

  const isPublicUrl = () =>
    publicPaths.map((path) => matchPath({ path }, location.pathname)).some((val) => val);

  useEffect(() => {
    const { pathname } = location;
    if (isPublicUrl()) return;
    let loggedRoute = '/dashboard';

    if (pathname !== '/login') {
      loggedRoute = pathname !== '/' ? pathname : '/dashboard';
    }
    navigate(isTokenValid ? loggedRoute : '/login');
  }, [token, isTokenValid]);

  return (
    <ThemeProvider theme={theme}>
      <Snackbar open={displayAlert} autoHideDuration={6000} onClose={hideAlert}>
        <Alert severity={currentAlert.level} sx={{ width: '100%' }}>
          {currentAlert.message}
        </Alert>
      </Snackbar>
      <Routes>
        <Route
          path="/partner/:partnerId/invoice/:invoiceId"
          element={<InvoiceReader />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/passwordReset" element={<PasswordReset />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route index element={<Home />} />
          <Route path="posts" element={<PostsRoute />} />
          <Route path="posts/:id" element={<PostForm />} />
          <Route path="users" element={<UsersRoute />} />
          <Route path="groups" element={<UsersRoute />} />
          <Route path="editGroup" element={<EditGroups />} />
          <Route path="manufacturers" element={<InvoicesRoute />} />
          <Route path="manufacturers/:id" element={<ManufacturerForm />} />
          <Route path="profile" element={<Profile />} />
          <Route path="taxes" element={<InvoicesRoute />} />
          <Route path="taxes/:id" element={<TaxForm />} />
          <Route path="assessments" element={<Assessments />} />
          <Route path="assessments/create" element={<CreateAssessment />} />
          <Route path="assessments/edit" element={<EditAssissments />} />
          <Route
            path="users/:id"
            element={<FormView entity="Usuário" width="100%" form={<UserForm />} />}
          />
          <Route path="partners" element={<UsersRoute />} />
          <Route path="partners/:id" element={<PartnerForm />} />
          <Route path="partner/create" element={<PartnerForm />} />
          <Route path="contracts" element={<InvoicesRoute />} />
          <Route
            path="contracts/:id"
            element={<FormView entity="Contrato" width="95%" form={<ContractForm />} />}
          />
          <Route path="products" element={<InvoicesRoute />} />
          <Route path="products/:id" element={<ProductForm />} />
          <Route path="wallets" element={<InvoicesRoute />} />
          <Route path="dashboards" element={<DashboardsScreen />} />
          <Route path="wallets/:id" element={<WalletForm />} />
          <Route path="invoices" element={<InvoicesRoute />} />
          <Route path="alerts" element={<Alerts />} />
          <Route path="alerts/:id" element={<AlertsForm />} />
          <Route
            path="invoices/:id"
            element={
              <FormView entity="Pedido De Venda" width="95%" form={<InvoiceForm />} />
            }
          />
          <Route path="calendar" element={<Calendar />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
