import { gql } from '@apollo/client';

export const GET_ALL_MANUFACTURERS = gql`
  query getAllManufacturer {
    getAllManufacturers {
      id
      name
    }
  }`;

export const GET_MANUFACTURER_BY_ID = gql`
  query getManufacturerById($id: String!) {
    getManufacturerById(id: $id) {
      id
      name
    }
  }`;

export const CREATE_MANUFACTURER = gql`
  mutation createManufacturer ($input: CreateManufacturerInput!) {
    createManufacturer (input: $input) {
      id
      name
      tenantId
    }
  }`;


export const UPDATE_MANUFACTURER = gql`
  mutation updateManufacturer ($input: UpdateManufacturerInput!) {
    updateManufacturer (input: $input) {
      id
    }
  }
`;

export const DELETE_MANUFACTURER = gql`
  mutation removeManufacturer ($id: String!) {
    removeManufacturer (id: $id)
  }
`;

